import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { addApplicationFieldsNeedToDisable, DATE_FORMAT, getKeysDataIfExist, SERVICE_TYPE_VALIDATION_IDS } from '../../config/constants';
import { encode } from '../../config/crypto';
import { ADD_LEAD, PH_LEAD_DETAILS, PH_LEADS_QUERY } from '../../services/leads.gql';
import { getRolesWithUsers } from '../../store/action/allAction';
import home_icon from '../../webroot/images/home.svg';
import FormsyInputField from '../elements/FormsyInputField';
import FormsySelect from '../elements/FormsySelect';
import Loader from '../elements/Loader';
import { ADD_APPLICATION, LEAD_SOURCE } from './formFields/AddApplication.json';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import * as moment from 'moment';

const AddApplication = () => {
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [leadId, setLeadId] = useState(0);
    const [ALL_OPTIONS, setALL_OPTIONS] = useState({});
    const client = useApolloClient();
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [addLead, setAddLead] = useState({ lead_source: "standalone", created_by_source_id: 1 });

    const dispatch = useDispatch();
    const { masterdata, UserInfo } = useSelector(({ masterdata, user }) => ({
        masterdata,
        UserInfo: JSON.parse(user.user_information),
    }));

    useEffect(() => {
        setALL_OPTIONS(prev => ({
            ...prev,
            dealerList: masterdata?.dealerList || [],
            soList: masterdata?.soList || []
        }));
    }, [masterdata]);

    useEffect(() => {
        let service_type = [];
        const expirtDate = moment(moment(addLead?.or_expiry_date)?.format(DATE_FORMAT));
        const currentDate = moment(moment()?.format(DATE_FORMAT));
        if (addLead?.or_expiry_date) {
            if (expirtDate.isBefore(currentDate)) {
                service_type = masterdata?.data?.service_type?.filter(service => SERVICE_TYPE_VALIDATION_IDS?.OLDER?.includes(service?.id)) || [];
            }
            else if (!expirtDate.isBefore(currentDate)) {
                service_type = masterdata?.data?.service_type?.filter(service => SERVICE_TYPE_VALIDATION_IDS?.GREATER?.includes(service?.id)) || [];
            }
        }
        setALL_OPTIONS(prev => ({
            ...prev,
            service_type

        }));
    }, [addLead?.or_expiry_date]);
    useEffect(() => {
        let transfer_type = [];
        if (addLead?.service_type_id) {
            transfer_type = masterdata?.data?.transfer_type?.filter(service => masterdata?.data?.service_type?.find(service => service?.id === addLead?.service_type_id)?.transfer_type_id?.includes(service?.id)) || [];
        }
        setALL_OPTIONS(prev => ({
            ...prev,
            transfer_type,
        }));
        setAddLead((currentValue) => ({
            ...currentValue,
            transfer_type_id: null
        }));
    }, [addLead?.service_type_id]);
    useEffect(() => {
        dispatch(getRolesWithUsers(client));
        getUCFLeadList();
    }, []);

    const isRadioSelect = useMemo(() => {
        const requiredFields = ["lead_source", "service_coverage_id"];
        if (addLead?.lead_source === 'standalone') {
            requiredFields.push("created_by_source_id");
        }
        const data = requiredFields?.every(field => addLead?.[field]);
        return data;
    }, [addLead]);

    const getUCFLeadList = () => {
        executeGraphQLQuery(PH_LEADS_QUERY(), client)
            .then((resp) => {
                if (resp?.data?.get_ucf_leads) {
                    setALL_OPTIONS(prev => ({
                        ...prev,
                        ucfLeads: resp.data?.get_ucf_leads?.map(lead => ({ value: lead?.id, label: lead?.id })) || [],

                    }));
                }
            })
            .catch((err) => {
            }).finally(() => {
            });
    };
    const getUCFLeadDetail = (lead_id) => {
        setIsLoading(true);
        executeGraphQLQuery(PH_LEAD_DETAILS(lead_id), client)
            .then((resp) => {
                if (resp?.data?.get_ucf_lead_detail) {
                    const newValues = {
                        ...getKeysDataIfExist(resp?.data?.get_ucf_lead_detail, [
                            'name',
                            'mobile',
                            'plate_number',
                            'so_id',
                            'sm_id',
                            'th_id',
                            'nh_id',
                            'dealer_id',
                            'email',
                            'house_no',
                            'street',
                            'brangey_id',
                            'city_id',
                            'region_id',
                            'postal_code',
                            'plate_number',
                            'make_id',
                            'model_id',
                            'variant_id',
                            'make_name',
                            'model_name',
                            'variant_name',
                            'make_year',
                            'car_color',
                            'vehicle_type_id',
                            'vehicle_category_id',
                            'other_make_name',
                            'other_model_name',
                            'other_variant_name',
                            'other_vehicle_type'
                        ])

                    };
                    setAddLead((currentValue) => ({
                        ...currentValue,
                        ...newValues
                    }));
                    return resp?.data?.get_ucf_lead_detail || {};
                }
                toast.error("Details not found!");
                return {};
            })
            .catch((err) => {
                toast.error("Details not found!");
                return {};
            }).finally(() => {
                setIsLoading(false);
            });
    };

    const handleSubmit = () => {
        setIsLoading(true);
        const variable = {
            api_called_by: "web",
            user_id: UserInfo?.id || 0,
            role_id: UserInfo?.role_id?.[0] || 0,
            createLeadInput: { ...addLead, or_expiry_date: moment(addLead?.or_expiry_date)?.format(DATE_FORMAT) },
        };
        if (addLead["lead_source"] === 'with_ucf') {
            variable.createLeadInput.created_by_source_id = 1;
        }
        delete variable?.createLeadInput?.lead_source;
        executeGraphQLMutation(ADD_LEAD(), variable, client)
            .then((resp) => {
                if (resp?.data?.create_lead?.is_duplicate) {
                    toast.error(resp?.data?.create_lead?.message);
                }
                else if (resp?.data?.create_lead?.id) {
                    toast.success(`Success, Lead Id is ${resp?.data?.create_lead?.lead_code}`);
                    setLeadId(resp?.data?.create_lead?.id);
                }
            })
            .catch((err) => {
                toast.error(JSON.stringify(err) || "Error while creating lead!");
                console.log(err);
            }).finally(() => setIsLoading(false));

    };

    const handlePatternFormatChange = (data, field_name) => {
        if (data.value !== undefined && field_name) {
            setAddLead((currentValue) => ({
                ...currentValue,
                [field_name]: field_name === "standard_ttp" ? +data.value : data.value,
            }));
        }
    };
    const handleChange = (event) => {
        if (event?.target?.id && event?.target?.value) {
            setAddLead((currentValue) => ({
                ...currentValue,
                [event?.target?.id]: event?.target?.value
            }));
        }
    };
    const handleSelectChange = (data, field_name) => {
        if (data && field_name) {
            let newValues = {};
            if (field_name === "service_type_id" && "standard_pirce" in data) {
                newValues.standard_ttp = +data?.standard_pirce;
            }
            if (field_name === "loan_id") {
                getUCFLeadDetail(+data?.value);
            }
            if (field_name === 'dealer_id' && addLead?.dealer_as_customer === 'yes') {
                newValues.name = "";
                newValues.mobile = "";
                newValues.dealer_as_customer = "no";
            }
            setAddLead((currentValue) => ({
                ...currentValue,
                [field_name]: +data?.value,
                ...newValues
            }));
        }
    };
    const handleRadioChange = event => {
        let currentValue = addLead;
        let newValue = {};
        if (event.target.name === "lead_source") {
            currentValue = {};
        }
        if (event.target.name === "created_by_source_id" && event.target.value === '2') {
            delete currentValue?.dealer_id;
        }
        newValue = { [event.target.name]: event.target.value };
        if (["created_by_source_id", "service_coverage_id"].includes(event.target.name)) {
            newValue[event.target.name] = +event.target.value;
        }
        if (["dealer_as_customer"].includes(event.target.name)) {
            if (event.target.value === 'yes') {
                newValue = { [event.target.name]: event.target.value, name: ALL_OPTIONS?.dealerList?.filter(data => data?.value === currentValue?.dealer_id).map(v => v.owner_name)[0], mobile: ALL_OPTIONS?.dealerList?.filter(data => data?.value === currentValue?.dealer_id).map(v => v.owner_mobile)[0] };
            } else {
                newValue = { [event.target.name]: event.target.value, name: "", mobile: "" };
            }
        }

        if (["lead_source", "created_by_source_id"].includes(event.target.name) && currentValue?.dealer_as_customer === 'yes') {
            newValue.name = "";
            newValue.mobile = "";
            newValue.dealer_as_customer = "no";
            newValue.service_coverage_id = 0;
        }
        setAddLead({
            ...currentValue,
            ...newValue
        });
    };
    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            setAddLead((currentValue) => ({
                ...currentValue,
                [field_name]: date,
                service_type_id: null,
                transfer_type_id: null
            }));
        }
    };

    const redirectTo = (page) => {
        if (page === 'home') {
            navigate(`/`);
        }
        else if (page === 'lead-detail') {
            navigate(`/lead-detail/customer-details/basic-details/${encode(leadId)}`);
        }

    };


    return (
        <div className="container-fluid">
            <div className="lead-detail-from-outer add-application">
                <div className="lead-form-heading">
                    <h2>Add Title Transfer Application</h2>
                </div>
                <Formsy
                    className="lead-form-filed"
                    autoComplete="off"
                    onValid={() => setAllowSubmit(true)}
                    onInvalid={() => setAllowSubmit(false)}
                    onValidSubmit={handleSubmit}
                    aria-label="lead form field basic"
                >
                    <div className="lead-form-heading p-lg-b p-lg-t">
                        <h2>Select Source</h2>
                    </div>

                    {LEAD_SOURCE?.map((field) => (
                        <Tab.Container id="left-tabs-example" defaultActiveKey={addLead[field?.id]} activeKey={addLead[field?.id]} onSelect={(k) => handleRadioChange({ target: { name: field?.id, value: k } })}>
                            <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                                <div className="tab-list d-flex">
                                    {field?.options?.map((option) => (
                                        <Nav.Item>
                                            <Nav.Link eventKey={option?.value}>{option?.label}</Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </div>
                            </Nav>
                        </Tab.Container >

                    ))}
                    <div className="row">
                        {ADD_APPLICATION?.map((field) => {
                            if (!((field?.dependentOn && field?.dependentValue.includes(addLead[field?.dependentOn])) || !field?.dependentOn)) {
                                return null;
                            }
                            if (['text', 'pattern-format', 'number-format'].includes(field?.type)) {
                                return (<fieldset class={`form-filed p-lg-t ${(field?.is_full_row && 'col-md-12') || 'col-md-3'}`}>
                                    <FormsyInputField
                                        id={field?.id}
                                        name={field?.name}
                                        type={field?.type}
                                        value={addLead?.[field?.name] || ""}
                                        placeholder=" "
                                        label={field?.label}
                                        onChange={['pattern-format', 'number-format'].includes(field?.type)
                                            ? (data) => handlePatternFormatChange(data, field?.name) : handleChange}
                                        format={field?.ApplyNumberformat ? field?.format : ''}
                                        validations={field?.validations}
                                        validationError={addLead[field?.name] ? field?.validationError : ''}
                                        readOnly={addLead?.lead_source === "with_ucf" ? addApplicationFieldsNeedToDisable(field?.name) : field?.readOnly}
                                        required={field?.required}
                                        showAsterisk={field?.showAsterisk}
                                    />
                                </fieldset>);
                            }
                            if (['tab'].includes(field?.type)) {
                                return (<Tab.Container className={`p-lg-t`} id="left-tabs-example" defaultActiveKey={addLead[field?.id]} activeKey={addLead[field?.id]} onSelect={(k) => handleRadioChange({ target: { name: field?.id, value: k } })}>
                                    <Nav variant="pills" className={`flex-column nav nav-pills ${field?.is_full_row && 'col-md-12'} p-lg-t`}>
                                        <div className="tab-list d-flex">
                                            {field?.options.map((option) => (
                                                <Nav.Item>
                                                    <Nav.Link eventKey={option.value}>{option.label}</Nav.Link>
                                                </Nav.Item>

                                            ))}
                                        </div>
                                    </Nav>
                                </Tab.Container >);
                            }
                            if (['radio1'].includes(field?.type) && addLead?.dealer_id && addLead?.lead_source === "standalone") {
                                return (
                                    <div className={`${(field?.is_full_row && 'col-md-12') || 'col-md-3'} p-lg-t`}>
                                        <label className="form-label">{field?.label}</label>
                                        <fieldset className={`form-filed radio-btn radio-type-txt p-xs-t`}>
                                            {field?.options.map((option) => (
                                                <div className="custom-control custom-checkbox" key={option.value}>
                                                    <input
                                                        id={option.value}
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name={option.name}
                                                        value={option.value}
                                                        checked={option.value === addLead?.[option.name] ? true : false}
                                                        onChange={handleRadioChange}
                                                        aria-label="handle change radio"
                                                        required={field?.required}
                                                    />
                                                    <label htmlFor={option.id} className="custom-control-label">{option.label}</label>
                                                </div>
                                            ))}
                                        </fieldset>
                                    </div>
                                );
                            }
                            if (['radio'].includes(field?.type)) {
                                return (
                                    <div className={`${(field?.is_full_row && 'col-md-12') || 'col-md-3'} p-lg-t`}>
                                        <label className="form-label">{field?.label}</label>
                                        <fieldset className={`form-filed radio-btn radio-type-txt p-xs-t`}>
                                            {field?.options.map((option) => (
                                                <div className="custom-control custom-checkbox" key={option.value}>
                                                    <input
                                                        id={option.value}
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name={option.name}
                                                        value={option.value}
                                                        checked={option.value === addLead?.[option.name] ? true : false}
                                                        onChange={handleRadioChange}
                                                        disabled={addLead?.lead_source === "with_ucf" ? addApplicationFieldsNeedToDisable(field?.name) : field?.readOnly}
                                                        aria-label="handle change radio"
                                                        required={field?.required}
                                                    />
                                                    <label htmlFor={option.id} className="custom-control-label">{option.label}</label>
                                                </div>
                                            ))}
                                        </fieldset>
                                    </div>
                                );
                            }
                            if (['dropdown'].includes(field?.type)) {
                                return (<fieldset
                                    class={`single-select ${(field?.is_full_row && 'col-md-12') || 'col-md-3'}  p-lg-t`}
                                >
                                    <FormsySelect
                                        name={field?.name}
                                        id={field?.id}
                                        inputProps={{
                                            options: ALL_OPTIONS?.[field?.optionsKey] || [],
                                            placeholder: field?.label,
                                            className: 'react-select',
                                            classNamePrefix: 'react-select',
                                            value: ALL_OPTIONS?.[field?.optionsKey]?.filter(
                                                ({ value }) => value?.toString() === addLead[field?.name]?.toString(),
                                            ) || "",
                                            // isDisabled: (['dealer_id'].includes(field?.name) && addLead["lead_source"] === "with_ucf") ? true : field?.readOnly,
                                            isDisabled: addLead?.lead_source === "with_ucf" ? addApplicationFieldsNeedToDisable(field?.name) : (field?.name === "service_type_id" && !addLead?.or_expiry_date) ? true : field?.readOnly,
                                        }}
                                        required={field?.required}
                                        showAsterisk={field?.showAsterisk}
                                        value={(addLead && addLead?.[field?.name]) || ""}
                                        onChange={(data) => handleSelectChange(data, field?.name)}
                                    />
                                </fieldset>);
                            }
                            if (["date-picker"].includes(field?.type)) {
                                return (<fieldset class={`${(field?.is_full_row && 'col-md-12') || 'col-md-3'}  p-lg-t`}>
                                    <FormsyDatePicker
                                        name={field.name}
                                        selectedDate={
                                            (addLead &&
                                                addLead[field?.name] &&
                                                new Date(addLead[field?.name])) ||
                                            ''
                                        }
                                        placeholder={field?.label}
                                        minDate={field?.minDate ? new Date(field?.minDate) : new Date(new Date().getFullYear() - 1, 0, 1)}
                                        maxDate={new Date().setFullYear(new Date().getFullYear() + 3)}
                                        value={
                                            (addLead?.[field.name] &&
                                                new Date(addLead[field.name])) ||
                                            ''
                                        }
                                        onDateSelect={(date) => handleDateChange(date, field.name)}
                                        dateFormat="dd MMM, yyyy"
                                        validations={field.validations}
                                        validationError={addLead[field.name] ? field.validationError : ''}
                                        required={field.required}
                                        showAsterisk={field.showAsterisk}
                                        hasError={
                                            addLead.hasOwnProperty(field.name) && !addLead[field.name]
                                                ? true
                                                : false
                                        }
                                        readOnly={field.readOnly}
                                    />
                                </fieldset>);
                            }
                            return null;
                        }
                        )}
                    </div>

                    {(leadId && (
                        <div className="btn-save-remarks t-center br-top m-lg go-home-view-lead">
                            <span className="btn-go-home m-md-r" onClick={() => redirectTo('home')}>
                                <img src={home_icon} className='m-sm-r' alt='' />
                                Go Home
                            </span>
                            <span className="btn-primary" onClick={() => redirectTo('lead-detail')}>
                                View Loan ID
                            </span>
                        </div>
                    )) ||
                        < div className="btn-save-remarks t-center br-top">
                            {(allowSubmit && isRadioSelect &&
                                <button aria-label="save naxt" type="submit" className="btn-primary">
                                    Submit
                                </button>) ||
                                <span className="span-disabled">Submit</span>}
                        </div>
                    }
                </Formsy>
            </div>
            {isLoading ? <Loader /> : null}
        </div>
    );
};
export default AddApplication;
