import * as moment from 'moment';
import { useState } from 'react';
const AgentChange = ({ lead, old_lead_agents, new_lead_agents }) => {
    const [isShowPrev, setIsShowPrev] = useState(false);
    return (<>
        <div className='change-agent-heading'>
            <span>Agent Changed</span>
            <label className='date'>{(lead?.leadDetail?.agent_change_date && moment(lead?.leadDetail?.agent_change_date)?.format('D MMM, YYYY')) || ""}</label>
        </div>

        <div className='select-agent-card'>
            <div className='d-flex agent-card-heading'>
                <span className='card-heading'>{new_lead_agents?.agent_name}
                    <span className='new-tag'>New</span>
                </span>
                <div className='agent-show-hide-txt' onClick={() => setIsShowPrev(!isShowPrev)}>{isShowPrev ? `Hide Previous Agent` : `View Previous Agent`}</div>
            </div>
            <ul>
                <li>
                    <label>Agent Charges</label>
                    <span>₱ {new_lead_agents?.agent_fee}</span>
                </li>
                <li>
                    <label>Final Charges</label>
                    <span>₱ {new_lead_agents?.final_agent_fee}</span>
                </li>
                <li>
                    <label>TAT in Days</label>
                    <span>{new_lead_agents?.agent_tat} Days</span>
                </li>
                <li>
                    <label>Final TAT</label>
                    <span>{new_lead_agents?.final_agent_tat} Days</span>
                </li>
            </ul>
            <div className='lto-code-txt'>
                <span>Outgoing LTO Office Code*</span>
                <label>{new_lead_agents?.lt_office_name || ""}</label>
            </div>
        </div>
        {
            isShowPrev ? <div className='select-agent-card'>
                <div className='d-flex agent-card-heading'>
                    <span className='card-heading'>{old_lead_agents?.agent_name}
                        <span className='old-tag'>Old</span>
                    </span>
                </div>
                <ul>
                    <li>
                        <label>Agent Charges</label>
                        <span>₱ {old_lead_agents?.agent_fee}</span>
                    </li>
                    <li>
                        <label>Final Charges</label>
                        <span>₱ {old_lead_agents?.final_agent_fee}</span>
                    </li>
                    <li>
                        <label>TAT in Days</label>
                        <span>{old_lead_agents?.agent_tat} Days</span>
                    </li>
                    <li>
                        <label>Final TAT</label>
                        <span>{old_lead_agents?.final_agent_tat} Days</span>
                    </li>
                </ul>
                <div className='lto-code-txt'>
                    <span>Outgoing LTO Office Code*</span>
                    <label>{old_lead_agents?.lt_office_name || ""}</label>
                </div>
            </div> : <></>
        }
    </>
    );
};
export default AgentChange;
