import { gql } from '@apollo/client';

export const ADD_STANDALONE_ORCR_LEAD = () => {
    return gql`mutation CreateLead( $api_called_by: API_CALL_BY!, $createLeadInput: CreateLeadStandaloneORCRInput!, $user_id: Float!) {
        standalone_create_lead_orcr(user_id: $user_id, api_called_by: $api_called_by, createLeadInput: $createLeadInput) {
            id
            message
        }
    }`;
};

export const DASHBOARD_STANDALONE_QUERY_ORCR = () => {
    return gql`
        {
            standalone_orcr_dashboard(api_called_by: web) {
                name
                lead_count
                color_code
                status_icon
                filter_params{
                    status_id
                }
                card_styles{
                   icon_className
                   max_icon_paths
                }
            }
        }
    `;
};

export const LEADS_QUERY_STANDALONE_ORCR = () => {
    return gql`
        mutation GetLeads(
            $GetStandaloneLeadInput: GetStandaloneLeadInput!
            $paginationInput: PaginationInput!
            $api_called_by: API_CALL_BY!
            $user_id: Float!
            $role_id: Float!
        ) {
            standalone_orcr_lead_list(
                filters: $GetStandaloneLeadInput
                paginationInput: $paginationInput
                api_called_by: $api_called_by
                user_id: $user_id
                role_id: $role_id
            ) {
                rows{
                        id
                        app_id
                        created_date
                        doc_status
                        status_id
                        lead_assigned_to
                        status
                        lead_source
                        lead_detail{
                            lead_id
                            registration_number
                            owner_name
                            engine_number
                            chassis_number
                            color
                            fuel_type
                            transmission
                            seating_capacity
                            address
                            model
                            make
                            make_year
                            plate
                            status
                            agent_fee
                            reject_reason
                            variant                           
                            other_reject_reason
                        }
                        lead_docs{
                            id
                            doc_id
                            doc_path
                            doc_name
                        }
                        app_id,
                        agent_fee
                    }
                    tabs{
                        label
                        count
                        type
                        filter_params{
                            status_id
                            lead_assigned_to
                            action_type
                        }
                    }
                    pagination{
                        total_page
                        total_records
                        prev_page
                        next_page
                        page_no
                    }
            }
        }
    `;
};

export const LEADS_QUERY_ORCR_STANDALONE_EXPORT = () => {
    return gql`
        mutation getExport($getLeadsInput: GetStandaloneLeadInput!,$user_id: Float!){
            standalone_orcr_lead_list_export(filters: $getLeadsInput,user_id: $user_id){
                report_data
            }
        }
    `;
};

export const GET_DOCUMENTS_LIST = () => gql`
    query {
        get_standalone_document_list(api_called_by: web) {
            id
            name
            min_upload
            max_upload
            parent_id
            is_required
            show_in_app
            doc_icon_url
            bg_color_color
            sort_number
            status
            child {
                id
                parent_id
                name
                max_upload
            }
            
        }
    }
`;

export const UPDATE_AGENT_FEE_AND_DOC_STANDALONE_ORCR = () => {
    return gql`
            mutation standalone_update_agent_fee_and_docs($updateagentinput: UpdateStandaloneAgentFeeInput!,$api_called_by: API_CALL_BY!,$user_id: Float!) {
                standalone_update_agent_fee_and_docs(updateagentinput: $updateagentinput, api_called_by: $api_called_by,user_id:$user_id) {
                    message
                }
            }
    `;
};

export const UPDATE_LEAD_ASSIGNED_TO_STANDALONE_ORCR = () => {
    return gql`
        mutation standalone_update_lead_assigned_to_orcr(
            $lead_id: Float!
            $lead_assigned_to: Float!
            $api_called_by: API_CALL_BY!
            $user_id: Float!
            $role_id: Float!
        ) {
            standalone_update_lead_assigned_to_orcr(
                lead_id: $lead_id
                lead_assigned_to: $lead_assigned_to
                api_called_by: $api_called_by
                user_id: $user_id
                role_id: $role_id
            ) {
                message
            }
        }
    `;
};

export const UPDATE_VERIFICATION_STATUS_STANDALONE_ORCR = () => {
    return gql`
            mutation standalone_update_verification_and_docs($UpdateVerificationStandaloneDocs:   UpdateVerificationStandaloneDocs!,$api_called_by: API_CALL_BY!,$user_id: Float!) {
                standalone_update_verification_and_docs(UpdateVerificationStandaloneDocs: $UpdateVerificationStandaloneDocs, api_called_by: $api_called_by,user_id:$user_id) {
                    message
                }
            }
    `;
};

export const OCR_DATA_STANDALONE =()=> {
    return gql`
        mutation extract_ocr_data($ExtractOCRData: ExtractOCRData!) {
            extract_ocr_data(ExtractOCRData: $ExtractOCRData) {
                message
                data
            }
        }
    `;
};