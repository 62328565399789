import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import crypto from '../../config/crypto';
import { AGENT_DASHBOARD_QUERY, DASHBOARD_EXCEPTION_QUERY, DASHBOARD_QUERY, DASHBOARD_QUERY_ORCR, DASHBOARD_UCF_QUERY_ORCR } from '../../services/dashboard.gql';
import Loader from '../elements/Loader';
import { DASHBOARD_STANDALONE_QUERY_ORCR } from '../../services/standalone-orcr.gql';

const SEARCH_TYPE = [
    { value: 'loan_id', label: 'Application No.' },
    { value: 'customer_name', label: 'Customer Name' },
    { value: 'customer_number', label: 'Customer number' },
    { value: 'plate_number', label: 'Plate number' },
];

const Dashboard = () => {
    const [dashboard, setDashboard] = useState([]);
    const [searchText, setSearchtext] = useState('');
    const [searchType, setSearchtype] = useState('loan_id');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState("first");
    const [ucfDashboardCount, setUcfDashboardCount] = useState([]);
    const [exceptionDahboard, setExceptionDashboardCount] = useState([]);
    const [agentDashboard, setAgentDashboardCount] = useState([]);
    const [standaloneDashboardCount, setStandaloneDashboardCount] = useState([]);
    const randomCardColorId = () => Math.floor(Math.random() * 5) + 1;
    const card_id = {
        1: 'one',
        2: 'two',
        3: 'three',
        4: 'four',
        5: 'five',
        6: 'two',
        7: 'four',
        8: 'five',
        9: 'three',
        10: 'four',
        11: 'two',
        12: 'one',
        13: 'three',
        14: 'five',
        15: 'four',
    };
    const client = useApolloClient();
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);

    let name = UserInfo?.name ? UserInfo.name : '';
    let userId = UserInfo && UserInfo.id ? UserInfo.id : 1;
    const navigate = useNavigate();
    let searchUrl = `/lead-list/search_text/${searchType}/${searchText}`;
    const handleSearch = () => {
        if (selectedTab === "first")
            searchType ? navigate({ pathname: searchUrl }) : toast.error('Please select search type first');
        else {
            searchUrl = `/orcr-verification/lead-list/${crypto.encode(
                JSON.stringify({
                    search_text: searchText,
                }),
            )}`;
            navigate({ pathname: searchUrl });
        }
    };

    const handleChange = (e) => {
        setSearchtext(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    useEffect(() => {
        setIsLoading(true);
        setDashboard([]);
        setUcfDashboardCount([]);
        setStandaloneDashboardCount([]);
        if (selectedTab === 'first') {
            executeGraphQLQuery(DASHBOARD_QUERY(UserInfo?.id || 0), client, 1)
                .then((res) => {
                    const response = res.data?.loan_dashboard_count?.loan_summary?.map((data) => ({
                        ...data,
                        color_id: randomCardColorId(),
                    }));
                    setDashboard(response);
                    setIsLoading(false);
                })
                .catch((err) => {
                    toast.error('Something went wrong');
                });
        } else if (selectedTab === 'second') {
            executeGraphQLQuery(DASHBOARD_QUERY_ORCR(), client, 1)
                .then((res) => {
                    const response = res.data?.orcr_dashboard?.map((data) => ({
                        ...data,
                        color_id: randomCardColorId(),
                    }));
                    setDashboard(response);
                    setIsLoading(false);
                })
                .catch((err) => {
                    toast.error('Something went wrong');
                });

            executeGraphQLQuery(DASHBOARD_UCF_QUERY_ORCR(), client, 1)
                .then((res) => {
                    const response = res.data?.ucf_orcr_dashboard?.map((data) => ({
                        ...data,
                        color_id: randomCardColorId(),
                    }));
                    setUcfDashboardCount(response);
                    setIsLoading(false);
                })
                .catch((err) => {
                    toast.error('Something went wrong');
                });
            executeGraphQLQuery(DASHBOARD_STANDALONE_QUERY_ORCR(), client, 1)
                .then((res) => {
                    const response = res.data?.standalone_orcr_dashboard?.map((data) => ({
                        ...data,
                        color_id: randomCardColorId(),
                    }));
                    setStandaloneDashboardCount(response);
                    setIsLoading(false);
                })
                .catch((err) => {
                    toast.error('Something went wrong');
                });
        } else {
            executeGraphQLQuery(DASHBOARD_EXCEPTION_QUERY(), client, 1)
                .then((res) => {
                    const response = res.data?.exception_dashboard_count?.map((data) => ({
                        ...data,
                        color_id: randomCardColorId(),
                    }));
                    setExceptionDashboardCount(response);
                    setIsLoading(false)
                }).catch((err) => {
                    toast.error('Something went wrong');
                });

            executeGraphQLQuery(AGENT_DASHBOARD_QUERY(), client, 1)
            .then((res) => {
                const response = res.data?.agent_dashboard_count?.map((data) => ({
                    ...data,
                    color_id: randomCardColorId(),
                }));
                setAgentDashboardCount(response);
                setIsLoading(false)
            }).catch((err) => {
                toast.error('Something went wrong');
            });
        }
    }, [user_information, userId, client, selectedTab]);

    return (
        <div className="dashboard-bg">
            <div className="dashboard-outer">
                <div className="heading">
                    <span className="subheading">Hello {name}</span>
                    <h1>Welcome to Title Transfer</h1>
                </div>
                {selectedTab === "first" &&
                    <div className="single-select dashboard-select-opt">
                        <div className="material">
                            <Select
                                options={SEARCH_TYPE}
                                placeholder={'Select Search Type'}
                                name={'search_type'}
                                optionLabel="label"
                                optionValue="value"
                                value={SEARCH_TYPE.filter((data) => data.value === searchType)}
                                onChange={(e) => {
                                    setSearchtype(e.value);
                                    setSearchtext('');
                                }}
                                aria-label="search type"
                            />
                        </div>
                    </div>
                }
                <div className="search-bx">
                    <input
                        type="text"
                        placeholder={selectedTab === "first" ? `Search by ${SEARCH_TYPE.filter((data) => data.value === searchType)?.[0]?.label}` : 'Search By Application Id and Plate Number'}
                        value={searchText}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    <button className="search-btn" onClick={handleSearch} disabled={!searchText}>
                        Search
                    </button>
                    <i className="ic-search"></i>
                </div>
                <div className="dashboard-toggle-tab">
                    {isLoading ? <Loader /> : <></>}
                    <Tab.Container id="left-tabs-example" defaultActiveKey={selectedTab}>
                        <Nav variant="pills" className="flex-column">
                            <div className="tab-list">
                                <Nav.Item>
                                    <Nav.Link onClick={() => setSelectedTab("first")} eventKey="first">Title Transfer Cases</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => setSelectedTab("third")} eventKey="third">ORCR Exceptional Cases</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => setSelectedTab("second")} eventKey="second">ORCR Verification</Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="dashboard-card">
                                    <div className="dashboard-card">
                                        {dashboard?.map((reportList, index) => (
                                            <Link
                                                key={index}
                                                to={
                                                    reportList.lead_count > 0
                                                        ? `/lead-list/${crypto.encode(
                                                            JSON.stringify({
                                                                status_id: reportList.filter_params.status_id,
                                                                doc_status: reportList.filter_params.doc_status,
                                                                ...((reportList?.filter_params?.action_type && { action_type: reportList?.filter_params?.action_type }) || {}),
                                                                ...((reportList?.filter_params?.payment_status && { payment_status: reportList?.filter_params?.payment_status }) || {})
                                                            }),
                                                        )}`
                                                        : ''
                                                }
                                            >
                                                <div className={`card color-${card_id[index + 1]}`}>
                                                    <div className="roung-bg">
                                                        {reportList.card_styles && (
                                                            <i className={reportList.card_styles.icon_className}>
                                                                {reportList.card_styles.max_icon_paths
                                                                    ? Array.from(
                                                                        Array(
                                                                            reportList.card_styles.max_icon_paths,
                                                                        ).keys(),
                                                                    ).map((i) => (
                                                                        <i key={i} className={`path${i + 1}`}></i>
                                                                    ))
                                                                    : null}
                                                            </i>
                                                        )}
                                                    </div>
                                                    <span className="count-txt">
                                                        {reportList.lead_count ? reportList.lead_count : 0}
                                                    </span>
                                                    <span className="card-txt">{reportList.name}</span>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className="dashboard-card">
                                    <div className="dashboard-card">
                                        {exceptionDahboard?.map((reportList, index) => (
                                            <Link
                                                key={index}
                                                to={
                                                    reportList.lead_count > 0
                                                        ? `/lead-list/${crypto.encode(
                                                            JSON.stringify({
                                                                status_id: reportList.filter_params.status_id,
                                                                doc_status: reportList.filter_params.doc_status,
                                                                ...((reportList?.filter_params?.action_type && { action_type: reportList?.filter_params?.action_type }) || {}),
                                                                ...((reportList?.filter_params?.payment_status && { payment_status: reportList?.filter_params?.payment_status }) || {})
                                                            }),
                                                        )}`
                                                        : ''
                                                }
                                            >
                                                <div className={`card color-${card_id[index + 1]}`}>
                                                    <div className="roung-bg">
                                                        {reportList.card_styles && (
                                                            <i className={reportList.card_styles.icon_className}>
                                                                {reportList.card_styles.max_icon_paths
                                                                    ? Array.from(
                                                                        Array(
                                                                            reportList.card_styles.max_icon_paths,
                                                                        ).keys(),
                                                                    ).map((i) => (
                                                                        <i key={i} className={`path${i + 1}`}></i>
                                                                    ))
                                                                    : null}
                                                            </i>
                                                        )}
                                                    </div>
                                                    <span className="count-txt">
                                                        {reportList.lead_count ? reportList.lead_count : 0}
                                                    </span>
                                                    <span className="card-txt">{reportList.name}</span>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                <br></br>
                                <h3>Agent Selection</h3>
                                <div className="dashboard-card">
                                    <div className="dashboard-card">
                                        {agentDashboard?.map((reportList, index) => (
                                            <Link
                                                key={index}
                                                to={
                                                    reportList.lead_count > 0
                                                        ? `/lead-list/${crypto.encode(
                                                            JSON.stringify({
                                                                status_id: reportList.filter_params.status_id,
                                                                doc_status: reportList.filter_params.doc_status,
                                                                ...((reportList?.filter_params?.action_type && { action_type: reportList?.filter_params?.action_type }) || {}),
                                                                ...((reportList?.filter_params?.payment_status && { payment_status: reportList?.filter_params?.payment_status }) || {})
                                                            }),
                                                        )}`
                                                        : ''
                                                }
                                            >
                                                <div className={`card color-${card_id[index + 1]}`}>
                                                    <div className="roung-bg">
                                                        {reportList.card_styles && (
                                                            <i className={reportList.card_styles.icon_className}>
                                                                {reportList.card_styles.max_icon_paths
                                                                    ? Array.from(
                                                                        Array(
                                                                            reportList.card_styles.max_icon_paths,
                                                                        ).keys(),
                                                                    ).map((i) => (
                                                                        <i key={i} className={`path${i + 1}`}></i>
                                                                    ))
                                                                    : null}
                                                            </i>
                                                        )}
                                                    </div>
                                                    <span className="count-txt">
                                                        {reportList.lead_count ? reportList.lead_count : 0}
                                                    </span>
                                                    <span className="card-txt">{reportList.name}</span>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <br></br>
                                <h3>Used Car Finance(UCF)</h3>
                                <div className="dashboard-card">
                                    <div className="dashboard-card">
                                        {ucfDashboardCount?.map((reportList, index) => (
                                            <Link
                                                key={index}
                                                to={
                                                    reportList.lead_count > 0
                                                        ? `/ucf-orcr-verification/lead-list/${crypto.encode(
                                                            JSON.stringify({
                                                                status_id: reportList.filter_params.status_id,
                                                                doc_status: reportList.filter_params.doc_status,
                                                                ...((reportList?.filter_params?.action_type && { action_type: reportList?.filter_params?.action_type }) || {})
                                                            }),
                                                        )}`
                                                        : ''
                                                }
                                            >
                                                <div className={`card color-${card_id[index + 1]}`}>
                                                    <div className="roung-bg">
                                                        {reportList.card_styles && (
                                                            <i className={reportList.card_styles.icon_className}>
                                                                {reportList.card_styles.max_icon_paths
                                                                    ? Array.from(
                                                                        Array(
                                                                            reportList.card_styles.max_icon_paths,
                                                                        ).keys(),
                                                                    ).map((i) => (
                                                                        <i key={i} className={`path${i + 1}`}></i>
                                                                    ))
                                                                    : null}
                                                            </i>
                                                        )}
                                                    </div>
                                                    <span className="count-txt">
                                                        {reportList.lead_count ? reportList.lead_count : 0}
                                                    </span>
                                                    <span className="card-txt">{reportList.name}</span>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div><br></br>
                                <h3>Inventory Funding(IF)</h3>
                                <div className="dashboard-card">
                                    <div className="dashboard-card">
                                        {dashboard?.map((reportList, index) => (
                                            <Link
                                                key={index}
                                                to={
                                                    reportList.lead_count > 0
                                                        ? `/orcr-verification/lead-list/${crypto.encode(
                                                            JSON.stringify({
                                                                status_id: reportList.filter_params.status_id,
                                                                doc_status: reportList.filter_params.doc_status,
                                                                ...((reportList?.filter_params?.action_type && { action_type: reportList?.filter_params?.action_type }) || {})
                                                            }),
                                                        )}`
                                                        : ''
                                                }
                                            >
                                                <div className={`card color-${card_id[index + 1]}`}>
                                                    <div className="roung-bg">
                                                        {reportList.card_styles && (
                                                            <i className={reportList.card_styles.icon_className}>
                                                                {reportList.card_styles.max_icon_paths
                                                                    ? Array.from(
                                                                        Array(
                                                                            reportList.card_styles.max_icon_paths,
                                                                        ).keys(),
                                                                    ).map((i) => (
                                                                        <i key={i} className={`path${i + 1}`}></i>
                                                                    ))
                                                                    : null}
                                                            </i>
                                                        )}
                                                    </div>
                                                    <span className="count-txt">
                                                        {reportList.lead_count ? reportList.lead_count : 0}
                                                    </span>
                                                    <span className="card-txt">{reportList.name}</span>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div><br></br>
                                <h3>Standalone OR/CR</h3>
                                <div className="dashboard-card">
                                    <div className="dashboard-card">
                                        {standaloneDashboardCount?.map((reportList, index) => (
                                            <Link
                                                key={index}
                                                to={
                                                    reportList.lead_count > 0
                                                        ? `/standalone-orcr-verification/lead-list/${crypto.encode(
                                                            JSON.stringify({
                                                                status_id: reportList.filter_params.status_id,
                                                                doc_status: reportList.filter_params.doc_status,
                                                                ...((reportList?.filter_params?.action_type && { action_type: reportList?.filter_params?.action_type }) || {})
                                                            }),
                                                        )}`
                                                        : ''
                                                }
                                            >
                                                <div className={`card color-${card_id[index + 1]}`}>
                                                    <div className="roung-bg">
                                                        {reportList.card_styles && (
                                                            <i className={reportList.card_styles.icon_className}>
                                                                {reportList.card_styles.max_icon_paths
                                                                    ? Array.from(
                                                                        Array(
                                                                            reportList.card_styles.max_icon_paths,
                                                                        ).keys(),
                                                                    ).map((i) => (
                                                                        <i key={i} className={`path${i + 1}`}></i>
                                                                    ))
                                                                    : null}
                                                            </i>
                                                        )}
                                                    </div>
                                                    <span className="count-txt">
                                                        {reportList.lead_count ? reportList.lead_count : 0}
                                                    </span>
                                                    <span className="card-txt">{reportList.name}</span>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </div>
    );
};
export default Dashboard;
