import { useApolloClient } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { AGENT_LTO_UPDATE_TYPE, CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../config/constants';
import crypto from '../../config/crypto';
import { AGENT_LTO_CHANGE, STATUS_PAYMENT_DATA } from '../../services/leads.gql';
import { getLoanDetail } from '../../store/action/allAction';
import Modal from '../elements/Modal';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import AddStatusPaymentDetails from './addPaymentStatus';
import DocumentGallery from './DocumentGallery';
import PaymentDetails from './PaymentDetails';
import StatusDetails from './StatusDetails';
import { Nav, Tab } from "react-bootstrap";
import Select from 'react-select';
import { AGENT_LIST_FOR_STA } from '../../services/agent.ggl';
import { STATUS_DETAILS_FORM, AGENT_FIELD } from './formFields/StatusDetailsFormFields.json';

const StatusPaymentDetails = () => {
    const dispatch = useDispatch();
    const [paymentStatusDetails, setPaymentStatusDetails] = useState([]);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const [Agentlto, setAgentlto] = useState(false);
    const [activeKey, setActiveKey] = useState("first");
    const [saveAgentLTOInput, setSaveAgentLTOInput] = useState({});
    const [agentList, setAgentList] = useState([]);
    const [allAgentList, setAllAgentList] = useState({});


    const current_url_main = window.location.href;
    let current_url = current_url_main.split('/');
    let param = current_url[current_url.length - 2];

    const { lead, remarksHistory, leadHistory, lto_offices, userInfo } = useSelector(({ lead, masterdata, user }) => ({
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadHistory: lead?.leadDetail?.lead_status_history,
        lto_offices: masterdata?.data?.lto_office_type,
        userInfo: user.user_information ? JSON.parse(user.user_information) || {} : {},
    }));

    const client = useApolloClient();
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE('loanDetail', 'status-details')?.is_edit_access;

    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.lead_assigned_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo && UserInfo.id ? UserInfo.id : 0;

    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];

    let isLeadAssigned = IS_USER_ACCESS_PAGE('loanDetail', 'status-details').is_super_admin
        ? false
        : !(lead.leadDetail?.lead_assigned_to && lead.leadDetail?.lead_assigned_to === UserInfo?.id);

    useEffect(() => {
        let form_type = param === 'status-details' ? 1 : 2;
        let leadId = crypto.decode(lead_id);
        executeGraphQLQuery(STATUS_PAYMENT_DATA(+leadId, form_type), client)
            .then((resp) => {
                if (resp?.data?.get_status_payment_details)
                    setPaymentStatusDetails(resp?.data?.get_status_payment_details);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [param]);

    const isAddStatusDisabled = useMemo(() => {
        let is_payment_details_freeze = lead?.leadDetail?.is_payment_details_freeze || '';
        if (param === 'payment-details') return is_payment_details_freeze && leadHistory?.filter((v) => [6].includes(v.status_id))?.length ? true : false;
        else if (param === 'status-details') return !lead?.leadDetail?.lead_detail?.outbound_lt_office_id || leadHistory?.filter((v) => [6].includes(v.status_id))?.length ? true : false;
    }, [leadHistory, param, lead?.leadDetail?.is_payment_details_freeze, lead?.leadDetail?.lead_detail?.outbound_lt_office_id]);

    const isSubmitDisabled = useMemo(() => {
        return !lead?.leadDetail?.lead_detail?.outbound_lt_office_id || !lead?.leadDetail?.is_stages_complete ? true : false;
    }, [lead?.leadDetail?.lead_detail?.outbound_lt_office_id, lead?.leadDetail?.is_stages_complete]);

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(false);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    const showRemarkPopup = () => {
        setViewTimeLine(false);
        setShowremarkform(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideRemarkPopup = () => {
        setShowremarkform(false);
        document.body.classList.remove('overflow-hidden');
    };

    const navigateToNewOrcr = () => {
        navigate(`/lead-detail/new-orcr/${lead_id}`);
    };

    const showModalAgentlto = () => {
        setAgentlto(true);
        // document.body.classList.add('overflow-hidden');
    };

    const hideModalAgentlto = () => {
        setAgentlto(false);
        // document.body.classList.remove('overflow-hidden');
    };


    const submitAgentLtoChange = (updatedData = {}) => {
        const lead_id = lead.leadDetail.id;
        const variables = {
            saveAgentLTOInput: {
                lead_id,
                user_id: userInfo?.id,
                role_id: userInfo?.role_id?.[0] || 0,
                api_called_by: "web",
                ...updatedData

            }
        };
        executeGraphQLMutation(
            AGENT_LTO_CHANGE,
            variables,
            client,
        )
            .then(async (res) => {
                if (res?.data?.agent_lto_change?.message) {
                    toast.success(res?.data?.agent_lto_change?.message || 'Success');
                    dispatch(getLoanDetail(lead_id, client));
                } else {
                    toast.error(res?.data?.agent_lto_change?.message);
                }
            })
            .catch((error) => {
                toast.error(error.toString().replace('ApolloError:', ''));
            }).finally(() => {
                setSaveAgentLTOInput({});
                hideModalAgentlto();
            });
    };

    const handleSelectChange = (e, field, isNeedToUpdate = false) => {
        let newFields = {};
        if (["outbound_lt_office_id"].includes(field) && isNeedToUpdate) {
            newFields[field] = e?.id;
            submitAgentLtoChange({ [field]: newFields[field], update_type: AGENT_LTO_UPDATE_TYPE.LTO });
        }
        else if (["outbound_lt_office_id"].includes(field) && !isNeedToUpdate) {
            newFields[field] = e?.id;
        }
        else if (["agent_id"].includes(field)) {
            newFields[field] = e?.id;
            newFields.agent_details = {
                agent_id: e?.id || 0,
                agent_name: e?.label || "",
                agent_fee: e?.agent_fee || 0,
                agent_tat: e?.agent_tat || 0,
                standard_price: e?.standard_price || 0,
                final_agent_fee: e?.agent_fee || 0,
                final_agent_tat: e?.agent_tat || 0,
                final_standard_price: e?.standard_price || 0,
            };
        }
        setSaveAgentLTOInput(prev => ({
            ...prev,
            ...newFields
        }));
    };
    const handleSubmitAgentLtoChange = () => {
        const dataToSend = {
            agent_id: saveAgentLTOInput?.agent_details?.agent_id || 0,
            agent_name: saveAgentLTOInput?.agent_details?.agent_name || "",
            agent_fee: saveAgentLTOInput?.agent_details?.agent_fee || 0,
            final_agent_fee: saveAgentLTOInput?.agent_details?.final_agent_fee || 0,
            agent_tat: saveAgentLTOInput?.agent_details?.agent_tat || 0,
            final_agent_tat: saveAgentLTOInput?.agent_details?.final_agent_tat || 0,
            standard_price: saveAgentLTOInput?.agent_details?.standard_price || 0,
            final_standard_price: saveAgentLTOInput?.agent_details?.final_standard_price || 0,
            ...((saveAgentLTOInput?.outbound_lt_office_id && saveAgentLTOInput?.outbound_lt_office_id !== lead?.leadDetail?.lead_detail?.outbound_lt_office_id) ? { outbound_lt_office_id: saveAgentLTOInput?.outbound_lt_office_id } : {})
        };
        dataToSend.update_type = (dataToSend?.agent_id && dataToSend?.agent_name && AGENT_LTO_UPDATE_TYPE.AGENT) || AGENT_LTO_UPDATE_TYPE.LTO;
        submitAgentLtoChange(dataToSend);
    };

    useEffect(() => {
        if (lead?.leadDetail?.lead_detail?.inbound_lt_office_id && lead?.leadDetail?.service_type_id) {
            ltoAgentList(lead?.leadDetail?.lead_detail?.inbound_lt_office_id, lead?.leadDetail?.service_type_id);
        }
    }, [lead?.leadDetail?.lead_detail?.inbound_lt_office_id, lead?.leadDetail?.service_type_id]);

    const ltoAgentList = (lt_office_id, service_type_id) => {
        executeGraphQLQuery(AGENT_LIST_FOR_STA(), client, { lt_office_id, service_type_id })
            .then((resp) => {
                if (resp?.data?.agent_list_for_sta) {
                    let { mapped, unmapped } = resp?.data?.agent_list_for_sta?.reduce((result, agent) => {
                        result[agent?.is_mapped ? `mapped` : `unmapped`].push(agent);
                        return result;
                    }, { mapped: [], unmapped: [] });
                    setAgentList(resp?.data?.agent_list_for_sta);
                    setAllAgentList({ mapped, unmapped });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const sectionInfo =
        param === 'status-details'
            ? { section: 'Status details', sub_section: 'Status details' }
            : { section: 'Payment details', sub_section: 'Payment details' };

    return (
        <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading d-flex">
                        <h2>{param === 'status-details' ? 'Status Details' : 'Payment Details'}</h2>
                        <div className='d-flex lto-add-status-btn'>
                            {param === "status-details" ? (
                                (!lead?.leadDetail?.lead_detail?.outbound_lt_office_id || isAddStatusDisabled || getContext.markAsFreezed || accessCondition || isLeadAssigned) ? <span className='span-disabled add-status-btn btn-auto-width'>Change Agent/ LTO</span> : <button onClick={showModalAgentlto} className='btn-line add-status-btn change-agent-btn btn-auto-width'>Change Agent/ LTO</button>) : <></>}
                            <button
                                type="button"
                                className="btn-line add-status-btn"
                                onClick={() => showModalViewTimeLine()}
                                disabled={isAddStatusDisabled || getContext.markAsFreezed || accessCondition || isLeadAssigned}
                            >
                                <i className="ic-add m-xs-r"></i>
                                {param === 'status-details' ? 'Add Status' : 'Add Payment Proof'}
                            </button>
                        </div>
                    </div>
                    <div className="status-payment-detail-outer">
                        {param === 'status-details' ? (
                            <StatusDetails paymentStatusDetails={paymentStatusDetails} lto_offices={lto_offices} lead={lead} saveAgentLTOInput={saveAgentLTOInput} setSaveAgentLTOInput={setSaveAgentLTOInput} agentList={agentList} allAgentList={allAgentList} handleSelectChange={handleSelectChange} />
                        )
                            : (
                                <PaymentDetails paymentStatusDetails={paymentStatusDetails} />
                            )

                        }
                    </div>

                    <div className="btn-save-remarks p-lg-t p-l-30">
                        {isSubmitDisabled || getContext.markAsFreezed || accessCondition || isLeadAssigned ? (
                            <span className="span-disabled">Next</span>
                        ) : (
                            <button aria-label="save naxt" type="submit" className="btn-primary" onClick={navigateToNewOrcr}>
                                Next
                            </button>
                        )}
                        <button
                            aria-label="remarks history"
                            type="button"
                            className="btn-line"
                            onClick={showRemarkPopup}
                        >
                            Remarks
                            <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="Add-Financer-popup add-payment-record-popup">
                <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                    <AddStatusPaymentDetails lead={lead} param={param} user_id={user_id} paymentStatusDetails={paymentStatusDetails} />
                </Modal>
            </div>
            <div className="view-timeline-popup">
                <Modal show={showremarkform} handleClose={hideRemarkPopup}>
                    <div className="modal-header">
                        <h2>Remark History</h2>
                    </div>
                    <LeadRemarkHistory
                        showremarkform={showremarkform}
                        markAsFreezed={getContext.markAsFreezed}
                        accessCondition={accessCondition || isLeadAssigned}
                        sectionInfo={sectionInfo}
                    />
                </Modal>
            </div>
            <div className="view-timeline-popup change-agent-lto-popup">
                <Modal show={Agentlto} handleClose={hideModalAgentlto}>
                    <div className="modal-header">
                        <h2>Change Agent/LTO</h2>
                    </div>
                    <div className='modal-body'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                            <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                                <div className="tab-list d-flex">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Change Agent</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Change LTO Office</Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className='body'>
                                        {/* <fieldset class="search-bx p-lg-b">
                                            <div class="material">
                                                <input id="" type="text" aria-label="search text" name="search_text" class="form-input" title="SEARCH_TEXT" placeholder="Search Agent" value="" />
                                                <i class="ic-search"></i>
                                            </div>
                                        </fieldset> */}
                                        {AGENT_FIELD?.map(field => (
                                            <fieldset className="single-select">
                                                <div className="material">
                                                    <Select
                                                        options={agentList?.filter(item => item?.id !== lead?.leadDetail?.agent_id)}
                                                        placeholder={field?.label || ""}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        value={agentList?.filter(({ value }) => +value === saveAgentLTOInput?.agent_id)}
                                                        onChange={(e) => handleSelectChange(e, field?.name)}
                                                        aria-label={field?.label || ""}
                                                    />
                                                </div>
                                            </fieldset>))}
                                        {
                                            saveAgentLTOInput?.agent_details ?
                                                <div className='select-agent-card m-xl-t'>
                                                    <span className='card-heading'>{saveAgentLTOInput?.agent_details?.agent_name}</span>
                                                    <ul>
                                                        <li>
                                                            <label>Agent Charges</label>
                                                            <span>₱ {saveAgentLTOInput?.agent_details?.agent_fee || 0}</span>
                                                        </li>
                                                        <li>
                                                            <label>Charges</label>
                                                            <span>₱ {saveAgentLTOInput?.agent_details?.final_agent_fee || 0}</span>
                                                        </li>
                                                        <li>
                                                            <label>TAT in Days</label>
                                                            <span>{saveAgentLTOInput?.agent_details?.agent_tat || 0} Days</span>
                                                        </li>
                                                        <li>
                                                            <label>Final TAT</label>
                                                            <span>{saveAgentLTOInput?.agent_details?.final_agent_tat || 0} Days</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                : <></>
                                        }
                                    </div>

                                </Tab.Pane >
                                <Tab.Pane eventKey="second">
                                    <div className='row body'>
                                        <fieldset className="single-select col-md-12">
                                            {STATUS_DETAILS_FORM?.map(field => (
                                                <div className="material">
                                                    <Select
                                                        options={lto_offices?.filter(item => item?.id !== lead?.leadDetail?.lead_detail?.outbound_lt_office_id)}
                                                        placeholder={field?.label || ""}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        value={lto_offices?.filter(({ value }) => +value === (saveAgentLTOInput?.outbound_lt_office_id))}
                                                        onChange={(e) => handleSelectChange(e, field?.name)}
                                                        aria-label={field?.label || ""}
                                                    />
                                                </div>
                                            ))}
                                        </fieldset>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content >
                        </Tab.Container >
                    </div>
                    <div className='modal-footer p-lg-t'>
                        {
                            (saveAgentLTOInput?.agent_details || saveAgentLTOInput?.outbound_lt_office_id) ?
                                <button className='btn-primary  btn-auto-width p-xl-l p-xl-r' onClick={() => handleSubmitAgentLtoChange()}>Change</button> : <span className="span-disabled">Change</span>
                        }
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default StatusPaymentDetails;
