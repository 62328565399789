import { gql } from '@apollo/client';
export const GET_MASTER_DATA = () => gql`
    {
        masterdata(api_called_by: web) {
            vehicle_usage {
                id
                label
                value
                vehicle_type {
                    id
                    label
                    value
                }
            }
            source_type {
                id
                label
                value
            }
            coverage_type {
                id
                label
                value
            }
            fuel_type {
                id
                label
                value
            }
            service_type {
                id
                label
                value
                standard_pirce
                transfer_type_id
            }
            transfer_type {
                id
                label
                value
            }
            sub_status_list {
                id
                status_id
                sub_status_name
                sort_order
            }
            region_lto_office {
                id
                label
                value
                region_lto_office {
                    id
                    label
                    value
                    region_id
                }
            }
            lto_office_type {
                id
                label
                value
            }
            mode_of_payment {
                id
                label
                value
            }
            make_year {
                id
                label
                value
            }
            agent_doc_list {
                id
                name
                label
                parent_id
                min_upload
                max_upload
            }
            status_update_stages{
                id
                value
                label
                start_label
                complete_label
                service_ids
            }
            payment_type{
                id
                value
                label
            }
            vehicle_category {
                id
                label
                value
            }
        }
    }
`;

export const GET_STATUS_LIST = () => gql`
    {
        get_status_list {
            id
            name
            color_code
            status_icon
            sort_order
        }
    }
`;

export const GET_VEHICLE_USAGE = () => gql`{
    get_vehicle_usage(api_called_by: web) {
        vehicle_usage {
            id
            label
            value
            vehicle_type {
                id
                label
                value
            }
        }
	}
}`;

export const GET_MASTER_DATA_MMV = (vehicle_type = 0) => gql`{
	masterdata(api_called_by: web,vehicle_type:${vehicle_type}) {
    vehicle_usage {
        id
        label
        value
        vehicle_type {
            id
            label
            value
        }
    }
      make {
        make
        id
      }
      model {
        m
        id
        mk_id
        s_year
        e_year
      }
      version {
        vn
        vn_id
        mk_id
        md_id
        s_year
        e_year
        c_b_type
      }
	}
}
`;

export const GET_AGENT_LIST = (outbound_lt_office_id = 0, service_type_id = 0) => gql`
    query Agent_list {
        agent_list (outbound_lt_office_id: ${outbound_lt_office_id}, service_type_id: ${service_type_id}) {
            id
            agent_name
        }
    }
`;

export const OPS_EXECUTIVE_LIST = () => gql`
    query Ops_executive_list {
        ops_executive_list {
            id
            name
            label
        }
    }
`;

export const GET_DEALER_LIST = () => gql`
    query Get_dealer_list {
        get_dealer_list {
            dealer_list {
                value
                label
                owner_name
                owner_mobile
            }
        }
    }
`;

export const FINANCIER_QUERY = gql`
    query {
        financier_list {
            id
            financierlang {
                financier_name
                financier_id
            }
        }
    }
`;

export const TL_USER_LIST = () => gql`
    query TL_user_list {
        tl_user_list {
            id
            name
            label
        }
    }
`;

