import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { getFileExtensionFromURL, VERIFICATION_DOC_IDS } from '../../config/constants';
import { GENERATE_UCF_SUMMARY } from '../../services/leads.gql';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyTextAreaAutosize from '../elements/FormsyTextAreaAutosize';
import Loader from '../elements/Loader';
export default function GenerateReport({ lead, user_id, hideVerificationDocsModel, getLeadList, getLeadsInput, paginationInput, apiCalledBy }) {
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const client = useApolloClient();
    const [summary, setSummary] = useState({
        plate_number: "",
        lead_id: "",
        mv_file_number: "",
        engine_number: "",
        chassis_number: "",
        registration_information: "",
        ownership: "",
        registration_renewal: "",
        incident: "",
        docs: []
    })
    useEffect(() => {
        setSummary({
            ...summary,
            lead_id: lead?.id,
            plate_number: lead?.lead_detail?.plate_number,
            docs: lead?.lead_docs?.filter((doc) => {
                if (doc?.doc_id === VERIFICATION_DOC_IDS[apiCalledBy]) {
                    delete doc['__typename']
                    return doc
                }
                return null
            })
        })
    }, [lead]);
    const handleChange = (e) => {
        const name = e.target.name;
        setSummary({
            ...summary,
            [name]: e.target.value
        })
    };

    // generate report
    const submitReport = async (e) => {
        setIsLoading(true);
        let pdf_data = {
            ...summary,
            lead_docs: summary.docs.filter((doc) => {
                // Include docs that are NOT PDFs
                return !['pdf', 'PDF'].includes(getFileExtensionFromURL(doc.doc_path));
            }).map((doc) => {
                // Transform the docs into the desired HTML representation
                return `<br/><img src="${doc.doc_path}" style="width: 80%; margin-left: 10%;"/>`;
            }),
            lead_pdf_docs: summary.docs.filter((doc) => {
                // Include docs that ARE PDFs
                return ['pdf', 'PDF'].includes(getFileExtensionFromURL(doc.doc_path));
            }).map((doc) => doc.doc_path),
        };
        delete pdf_data["docs"]

        let variables = {
            api_called_by: apiCalledBy || 'web',
            user_id: user_id || 0,
            GenerateReportInput: pdf_data
        }
        await executeGraphQLMutation(GENERATE_UCF_SUMMARY(), variables, client)
            .then(res => {
                toast.success(res?.data?.generate_report_ucf?.message)
                getLeadList({
                    getLeadsInput,
                    paginationInput
                });
                hideVerificationDocsModel();
            })
            .catch((err) => {
                toast.error(err.toString().replace('ApolloError:', ''));
            })
            .finally(() => setIsLoading(false))
    };


    return (
        <>
            {isLoading && <Loader />}
            <Formsy
                className="lead-form-filed"
                autoComplete="off"
                onValid={() => setAllowSubmit(true)}
                onInvalid={() => setAllowSubmit(false)}
                aria-label="lead form field basic"
                onSubmit={submitReport}
            >
                <div className='row'>
                    <br />
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <FormsyInputField
                                id="plate_number"
                                type="text"
                                placeholder=""
                                label="Plate Number"
                                name="plate_number"
                                className="form-input"
                                value={summary.plate_number}
                                onChange={handleChange}
                                required={true}
                                disabled={true}
                            />
                        </div>
                    </fieldset>
                    <br />
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <FormsyInputField
                                id="mv_file_number"
                                type="text"
                                placeholder=""
                                label="MV File Number"
                                name="mv_file_number"
                                className="form-input"
                                value={summary.mv_file_number}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>
                    </fieldset>
                </div>
                <br />
                <div className='row'>
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <FormsyInputField
                                id="engine_number"
                                type="text"
                                placeholder=""
                                label="Engine Number"
                                name="engine_number"
                                className="form-input"
                                value={summary.engine_number}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>
                    </fieldset>
                    <br />
                    <fieldset className="form-filed col-md-6">
                        <div className="material">
                            <FormsyInputField
                                id="chassis_number"
                                type="text"
                                placeholder=""
                                label="Chassis Number"
                                name="chassis_number"
                                className="form-input"
                                value={summary.chassis_number}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>
                    </fieldset>
                </div>
                <br />
                <div className='row'>
                    <fieldset className="form-filed col-md-12">
                        <div className="material">
                            <FormsyTextAreaAutosize
                                name={"registration_information"}
                                id={"registration_information"}
                                required={true}
                                value={summary.registration_information}
                                label="Registration Information"
                                onChange={handleChange}
                            />
                            <label data-label="Registration Information *" className="form-label"></label>
                        </div>
                    </fieldset>
                </div>
                <br />
                <div className='row'>
                    <fieldset className="form-filed col-md-12">
                        <div className="material">
                            <FormsyTextAreaAutosize
                                name={"ownership"}
                                id={"ownership"}
                                required={true}
                                value={summary.ownership}
                                label="Ownership"
                                onChange={handleChange}
                            />
                            <label data-label="Ownership *" className="form-label"></label>
                        </div>
                    </fieldset>
                </div>
                <br />
                <div className='row'>
                    <fieldset className="form-filed col-md-12">
                        <div className="material">
                            <FormsyTextAreaAutosize
                                name={"registration_renewal"}
                                id={"registration_renewal"}
                                required={true}
                                value={summary.registration_renewal}
                                label="Registration Renewal"
                                onChange={handleChange}
                            />
                            <label data-label="Registration Renewal *" className="form-label"></label>
                        </div>
                    </fieldset>
                </div>
                <br />
                <div className='row'>
                    <fieldset className="form-filed col-md-12">
                        <div className="material">
                            <FormsyTextAreaAutosize
                                name={"incident"}
                                id={"incident"}
                                required={true}
                                value={summary.incident}
                                label="Incident"
                                onChange={handleChange}
                            />
                            <label data-label="Incident *" className="form-label"></label>
                        </div>
                    </fieldset>
                </div>

                <div className="btn-save-remarks p-lg-t">
                    {!allowSubmit ? (
                        <span className="span-disabled">Generate Report</span>
                    ) : (
                        <button aria-label="save naxt" type="submit" className="btn-primary">
                            Generate Report
                        </button>
                    )}
                </div>
            </Formsy>
        </>
    );
}