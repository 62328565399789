import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import Select, { components } from 'react-select';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { ADD_STANDALONE_ORCR_LEAD, GET_DOCUMENTS_LIST, OCR_DATA_STANDALONE } from '../../services/standalone-orcr.gql';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import GeneralService from '../../services/generalService';
import Loader from '../elements/Loader';
import { STANDALONE_ORCR_STATUS } from '../../config/constants';
import Formsy from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyTextAreaAutosize from '../elements/FormsyTextAreaAutosize';
import DocumentStandaolneGallery from './DocumentGallery';
import { useNavigate } from 'react-router-dom';
import FormsySelect from '../elements/FormsySelect';
import { FuelType, ReasonNotVerify, Transmission, SeatingCapacity } from '../../config/standaloneOrcr';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const OcrDocUpload = () => {

    let navigate = useNavigate();
    const [groupDocs, setGroupDocs] = useState([]);

    const [allowSubmit, setAllowSubmit] = useState(false);

    const client = useApolloClient();
    const [addLead, setAddLead] = useState({
        owner_name: "",
        registration_number: "",
        engine_number: "",
        chassis_number: "",
        fuel_type: "",
        color: "",
        seating_capacity: "",
        address: "",
        model: "",
        make: "",
        make_year: "",
        plate: "",
        transmission: "",
        agent_fee: "",
        status: STANDALONE_ORCR_STATUS.RECIEVED.toString(),
        reject_reason: "",
        other_reject_reason: "",
        variant: ""
    });

    const [isLoading, setIsLoading] = useState(false);
    const [leadId, setLeadId] = useState(0);
    const { UserInfo } = useSelector(({ user }) => ({
        UserInfo: JSON.parse(user.user_information),
    }));

    const [documents, setDocuments] = useState({ '1': [], '2': [], '3': [], '4': [] });
    const [uploadDocs, setUploadDocs] = useState({ '1': [], '2': [], '3': [], '4': [] });

    useEffect(() => {
        getDocDetails()
    }, [])

    const getDocDetails = () => {
        setIsLoading(true)
        executeGraphQLQuery(GET_DOCUMENTS_LIST(), client)
            .then((resp) => resp.data)
            .then((res) => {
                setGroupDocs(res.get_standalone_document_list)
            })
            .finally(() => setIsLoading(false))

    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setAddLead((currentValue) => ({
                ...currentValue,
                [field_name]: data.value,
            }));
        }
    };

    const handleSubmit = (e) => {

        let uploadData = { ...addLead, lead_docs: [] };;
        for (let key in uploadDocs) {
            uploadData.lead_docs = [...uploadData.lead_docs, ...uploadDocs[key]];
        }
        const hasCR = uploadData.lead_docs.find((lead) => lead.doc_id === 1);
        const hasOR = uploadData.lead_docs.find((lead) => lead.doc_id === 2);
        const isValidMakeYear = !isNaN(uploadData.make_year)

        if (hasCR && hasOR && isValidMakeYear) {
            setIsLoading(true);
            uploadData = { ...uploadData, agent_fee: addLead.agent_fee === "" ? null : addLead.agent_fee, make_year: parseInt(uploadData.make_year) }
            const variable = {
                api_called_by: "web",
                user_id: UserInfo?.id || 0,
                role_id: UserInfo?.role_id?.[0] || 0,
                createLeadInput: uploadData,
            };
            executeGraphQLMutation(ADD_STANDALONE_ORCR_LEAD(), variable, client)
                .then((resp) => {
                    if (resp?.data?.standalone_create_lead_orcr?.is_duplicate) {
                        toast.error(resp?.data?.standalone_create_lead_orcr?.message);
                    }
                    else if (resp?.data?.standalone_create_lead_orcr?.id) {
                        toast.success(`Success, Lead Id is ${resp?.data?.standalone_create_lead_orcr?.id}`);
                        setLeadId(resp?.data?.standalone_create_lead_orcr?.id);
                    }
                })
                .catch((err) => {
                    toast.error(err.toString().replace('ApolloError:', '') || "Error while creating lead!");;
                }).finally(() => setIsLoading(false));
        } else {
            if (!hasCR)
                toast.error("CR Document is required")
            else if (!hasOR)
                toast.error("OR Document is required")
            else if (!isValidMakeYear)
                toast.error("Make year should be a number")
        }

    };

    const handleDocUpload = (e, docId) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('upload_type', 'finance_docs');
        formData.append("lead_id", "lead_id");
        formData.append('images', file);
        e.target.value = '';
        setIsLoading(true)
        GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }).then(async (res) => {
            if (res?.data?.data) {
                const newDoc = {
                    doc_id: parseInt(e.target.name),
                    doc_path: res?.data?.data[0]?.file_url,
                };

                if (file) {
                    const localPreview = URL.createObjectURL(file)
                    //const reader = new FileReader();

                    setDocuments((prevDocs) => ({
                        ...prevDocs,
                        [docId]: [...prevDocs[docId], localPreview]
                    }))
                };


                setUploadDocs((prevDocs) => ({
                    ...prevDocs,
                    [newDoc.doc_id]: [...prevDocs[newDoc.doc_id], newDoc],
                }));

                if (newDoc.doc_id === 1 && uploadDocs[newDoc.doc_id].length === 0) {
                    const filterData = {
                        ExtractOCRData: { doc_path: newDoc.doc_path },
                    };

                    await executeGraphQLMutation(OCR_DATA_STANDALONE(), filterData, client).then((res) => {
                        if (res?.data?.extract_ocr_data?.data) {
                            let data = JSON.parse(res?.data?.extract_ocr_data?.data);
                            const update = {};
                            if (data?.owner_name) update.owner_name = data.owner_name;
                            if (data?.plate_number) update.plate = data.plate_number;
                            if (data?.engine_number) update.engine_number = data.engine_number;
                            if (data?.chassis_number) update.chassis_number = data.chassis_number;
                            if (data?.make_name) update.make = data.make_name;
                            if (data?.make_year) update.make_year = Number(data.make_year)
                            if (data?.registration_number) update.registration_number = data.registration_number;
                            if (data?.car_color) update.color = data?.car_color;
                            if (data?.owner_address) update.address = data?.owner_address;
                            if (data?.model) update.model = data?.model;

                            if (data?.fuel_type) {
                                const res = FuelType.find(fuel => fuel.value.toLowerCase() === data.fuel_type.toLowerCase());
                                update.fuel_type = res?.value || "";
                            }

                            if (data?.seating_capacity) {
                                const res = SeatingCapacity.find(seating_capacity => seating_capacity.value.toLowerCase() === data.seating_capacity.toLowerCase());
                                update.seating_capacity = res?.value || "";
                            }

                            if (data?.transmission) {
                                const res = Transmission.find(transmission => transmission.value.toLowerCase() === data.transmission.toLowerCase());
                                update.transmission = res?.value || "";
                            }

                            setAddLead({ ...addLead, ...update });
                        }
                    });
                }
            }
        })
            .catch((e) => toast.error(e.message ? e.message : e))
            .finally(() => setIsLoading(false));
    };

    const handleImageClick = (docId) => {
        document.getElementById(`file-input-${docId}`).click();
    };


    const handleChange = (event) => {
        if (event.target.name) {
            setAddLead((currentValue) => ({
                ...currentValue,
                [event.target.name]: event.target.value,
            }));
        }

    };

    const handleSelectChange = (e, value) => {
        setAddLead((currentValue) => ({
            ...currentValue,
            [value]: e.value,
        }));
    }

    return (
        <>
            <div className="container-fluid">
                <div class="top-heading ">
                    <h1>Verify OR/CR Documents</h1>
                </div>
                <Formsy
                    className="lead-form-filed"
                    autoComplete="off"
                    onValid={() => setAllowSubmit(true)}
                    onInvalid={() => setAllowSubmit(false)}
                    onValidSubmit={handleSubmit}
                    aria-label="lead form field basic"
                >
                    <div className='ocr-doc-upload-outer'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <DocumentStandaolneGallery
                                    groupDocs={groupDocs}
                                    documents={documents}
                                    setDocuments={setDocuments}
                                    uploadDocs={uploadDocs}
                                    setUploadDocs={setUploadDocs}
                                    handleDocUpload={handleDocUpload}
                                    handleImageClick={handleImageClick}
                                />
                            </div>

                            <div className='col-md-6'>
                                <div className='ocr-upload-right-sec ocr-bg-inner lead-form-filed'>

                                    <Accordion>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Document Details
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='row p-lg-r p-lg-l'>
                                                    <br />
                                                    <fieldset className="form-filed col-md-12">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="owner_name"
                                                                type="text"
                                                                placeholder=""
                                                                label="Owner Name"
                                                                name="owner_name"
                                                                className="form-input"
                                                                value={addLead.owner_name}
                                                                onChange={handleChange}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <fieldset className="form-filed col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="registration_number"
                                                                type="text"
                                                                placeholder=""
                                                                label="Registration Number"
                                                                name="registration_number"
                                                                className="form-input"
                                                                value={addLead.registration_number}
                                                                onChange={handleChange}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <fieldset className="form-filed col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="plate"
                                                                type="text"
                                                                placeholder=""
                                                                label="Plate Number"
                                                                name="plate"
                                                                className="form-input"
                                                                value={addLead.plate}
                                                                onChange={handleChange}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="form-filed col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="make"
                                                                type="text"
                                                                placeholder=""
                                                                label="Make Brand"
                                                                name="make"
                                                                className="form-input"
                                                                value={addLead.make}
                                                                onChange={handleChange}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="form-filed col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="model"
                                                                type="text"
                                                                placeholder=""
                                                                label="Model"
                                                                name="model"
                                                                className="form-input"
                                                                value={addLead.model}
                                                                onChange={handleChange}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="form-filed col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="variant"
                                                                type="text"
                                                                placeholder=""
                                                                label="Variant"
                                                                name="variant"
                                                                className="form-input"
                                                                value={addLead.variant}
                                                                onChange={handleChange}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="form-filed col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="make_year"
                                                                type="number-format"
                                                                placeholder=""
                                                                label="Make Year"
                                                                name="make_year"
                                                                className="form-input"
                                                                value={addLead.make_year}
                                                                onChange={(data) => handlePatternFormatChange(data, 'make_year')}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <fieldset className="form-filed col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="engine_number"
                                                                type="text"
                                                                placeholder=""
                                                                label="Engine Number"
                                                                name="engine_number"
                                                                className="form-input"
                                                                value={addLead.engine_number}
                                                                onChange={handleChange}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <fieldset className="form-filed col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="chassis_number"
                                                                type="text"
                                                                placeholder=""
                                                                label="Chassis Number"
                                                                name="chassis_number"
                                                                className="form-input"
                                                                value={addLead.chassis_number}
                                                                onChange={handleChange}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <fieldset className="single-select col-md-6">
                                                        <div className="material">
                                                            <FormsySelect
                                                                name="fuel_type"
                                                                id="fuel_type"
                                                                inputProps={{
                                                                    options: FuelType,
                                                                    placeholder: "Fuel",
                                                                    className: 'react-select',
                                                                    classNamePrefix: 'react-select',
                                                                    value: FuelType.filter((data) => data.value === addLead.fuel_type)
                                                                }}
                                                                required={true}
                                                                value={FuelType.filter((data) => data.value === addLead.fuel_type)}
                                                                onChange={(e) => handleSelectChange(e, 'fuel_type')}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <fieldset className="single-select col-md-6">
                                                        <div className="material">
                                                            <FormsySelect
                                                                name="transmission"
                                                                id="transmission"
                                                                inputProps={{
                                                                    options: Transmission,
                                                                    placeholder: "Transmission",
                                                                    className: 'react-select',
                                                                    classNamePrefix: 'react-select',
                                                                    value: Transmission.filter((data) => data.value === addLead.transmission)
                                                                }}
                                                                required={true}
                                                                value={Transmission.filter((data) => data.value === addLead.transmission)}
                                                                onChange={(e) => handleSelectChange(e, 'transmission')}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <fieldset className="single-select col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="color"
                                                                type="text"
                                                                placeholder=""
                                                                label="Color"
                                                                name="color"
                                                                className="form-input"
                                                                value={addLead.color}
                                                                onChange={handleChange}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <fieldset className="single-select col-md-6">
                                                        <div className="material">
                                                            <FormsySelect
                                                                name="seating_capacity"
                                                                id="seating_capacity"
                                                                inputProps={{
                                                                    options: SeatingCapacity,
                                                                    placeholder: "Seating Capacity",
                                                                    className: 'react-select',
                                                                    classNamePrefix: 'react-select',
                                                                    value: SeatingCapacity.filter((data) => data.value === addLead.seating_capacity)
                                                                }}
                                                                required={true}
                                                                value={SeatingCapacity.filter((data) => data.value === addLead.seating_capacity)}
                                                                onChange={(e) => handleSelectChange(e, 'seating_capacity')}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <br />
                                                    <fieldset className="form-filed col-md-12">
                                                        <div className="material">
                                                            <FormsyTextAreaAutosize label="Address" placeholder=" " id='address' name="address" className="form-input" value={addLead.address} onChange={handleChange} required={true}></FormsyTextAreaAutosize>
                                                            <label data-label="Address *" className="form-label"></label>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Agent Fees
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='row p-lg-r p-lg-l'>
                                                    <fieldset className="form-filed col-md-6">
                                                        <div className="material">
                                                            <FormsyInputField
                                                                id="agent_fee"
                                                                type="number-format"
                                                                placeholder=""
                                                                label="Agent Fee"
                                                                name="agent_fee"
                                                                className="form-input"
                                                                value={addLead.agent_fee}
                                                                onChange={(data) => handlePatternFormatChange(data, 'agent_fee')}
                                                                required={uploadDocs["3"].length !== 0}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                    <div className='col-md-12 m-sm-t'>
                                                        <div className='upload-proof-txt'>
                                                            <input type="file" name="3" onChange={(e) => handleDocUpload(e, 3)} />
                                                            <label>Upload Agent Fee Proof</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Verification
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='row p-lg-r p-lg-l'>
                                                    <div className='col-md-12 m-sm-t'>
                                                        <div className='chip-btn-outer m-sm-t'>
                                                            <label class="chip-control chip-verfied m-md-r">
                                                                <input type="radio" name="verification" value="1" checked={addLead.status === STANDALONE_ORCR_STATUS.VERIFIED.toString()} onChange={(e) => setAddLead({ ...addLead, status: STANDALONE_ORCR_STATUS.VERIFIED.toString() })} />
                                                                <span> <i className='ic-check m-sm-r'></i> Verified</span>
                                                            </label>
                                                            <label class="chip-control chip-not-verfied">
                                                                <input type="radio" name="verification" value="2" checked={addLead.status === STANDALONE_ORCR_STATUS.NOT_VERIFIED.toString()} onChange={(e) => setAddLead({ ...addLead, status: STANDALONE_ORCR_STATUS.NOT_VERIFIED.toString() })} />
                                                                <span>
                                                                    <i className='ic-clearclose m-sm-r'></i> Not Verified</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {addLead.status === STANDALONE_ORCR_STATUS.NOT_VERIFIED.toString() && <>
                                                        <fieldset className="single-select col-md-12 p-sm-t">
                                                            <div className="material">
                                                                <Select components={{ ValueContainer: CustomValueContainer }}
                                                                    options={ReasonNotVerify}
                                                                    placeholder="Reason for Not Verify"
                                                                    className="react-select"
                                                                    classNamePrefix="react-select"
                                                                    value={ReasonNotVerify.filter((data) => data.value === addLead.reject_reason)}
                                                                    id='reject_reason'
                                                                    onChange={(e) => handleSelectChange(e, 'reject_reason')}
                                                                />
                                                            </div>

                                                        </fieldset>
                                                        <div className='col-md-12 m-md-t'>
                                                            <div className='upload-proof-txt'>
                                                                <input type="file" name="4" onChange={(e) => handleDocUpload(e, 4)} />
                                                                <label>Upload verification Failed Proof</label>
                                                            </div>
                                                        </div>

                                                        {addLead?.reject_reason === "Other" ? <fieldset className="form-filed text-area-filed col-md-12 p-lg-t">
                                                            <div className="material">
                                                                <FormsyTextAreaAutosize
                                                                    id={"other_reject_reason"}
                                                                    name={"other_reject_reason"}
                                                                    value={addLead?.other_reject_reason}
                                                                    placeholder=""
                                                                    label={"Other Reason Remarks"}
                                                                    onChange={handleChange}
                                                                    required={true}
                                                                />
                                                                <label data-label={"Other Reason Remarks"} className="form-label"></label>
                                                            </div>
                                                        </fieldset> : null}


                                                    </>
                                                    }
                                                    {addLead.status === STANDALONE_ORCR_STATUS.VERIFIED.toString() && <>
                                                        <div className='col-md-12 m-md-t'>
                                                            <div className='upload-proof-txt'>
                                                                <input type="file" name="4" onChange={(e) => handleDocUpload(e, 4)} />
                                                                <label>Upload verification Proof</label>
                                                            </div>
                                                        </div></>
                                                    }
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                    <div className='p-lg-t'>
                                        {!leadId ?
                                            <button
                                                className='btn-primary'
                                                type='submit'
                                                disabled={!allowSubmit || uploadDocs["1"].length === 0 || uploadDocs["2"].length === 0 || !(addLead.transmission && addLead.fuel_type && addLead.seating_capacity) || (addLead.status.toString() !== STANDALONE_ORCR_STATUS.RECIEVED.toString() && uploadDocs['4'].length === 0) || (addLead.agent_fee && uploadDocs['3'].length === 0)}
                                            >
                                                Submit Application
                                            </button> :
                                            <button className='btn-primary' type='button' onClick={() => navigate('/standalone-orcr-verification/lead-list')}>
                                                View Leads
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Formsy>
            </div>
            {isLoading && <Loader />}
        </>
    )

}
export default OcrDocUpload;