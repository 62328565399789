import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { LEADS_REMARK_QUERY, LEAD_DETAIL_QUERY } from '../../services/leads.gql';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { SAVE_REMARKS } from '../../services/leadremarks.gql';
import { GET_ACCESS_FEATURES } from '../../services/role.gql';
import {
    GET_AGENT_LIST,
    GET_DEALER_LIST,
    GET_MASTER_DATA,
    GET_MASTER_DATA_MMV,
    GET_STATUS_LIST,
    OPS_EXECUTIVE_LIST,
    FINANCIER_QUERY,
    GET_VEHICLE_USAGE,
    TL_USER_LIST
} from '../../services/masterdata.gql';
import { GET_BANKS } from '../../services/bank.ggl';
import { GET_ROLES_WITH_USERS } from '../../services/config.gql';

export const getLoanDetail = (postData, client) => {
    return async (dispatch) => {
        try {
            await executeGraphQLQuery(LEAD_DETAIL_QUERY(postData), client)
                .then(async (response) => {
                    if (response && response.data) {
                        let data = response.data.lead_details;
                        if (data) {
                            await dispatch(success(data));
                        }
                    } else dispatch(success({}));
                })
                .catch((error) => {
                    dispatch(success({}));
                });
        } catch (err) {
            dispatch(success({}));
        }
    };
    function success(payload) {
        return { type: 'GET_LEAD_DETAIL', payload };
    }
};

export const getLeadRemarks = (postData, client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(LEADS_REMARK_QUERY(postData.lead_id), client)
                .then((response) => {
                    if (response && response.data && response.data.get_remarks) {
                        let data = response.data.get_remarks;
                        // console.log('data',data)
                        if (data) {
                            dispatch(success(data));
                        }
                    } else dispatch(success([]));
                })
                .catch((error) => {
                    dispatch(success([]));
                });
        } catch (err) {
            dispatch(success([]));
        }
    };
    function success(payload) {
        return { type: 'LEADS_REMARK_QUERY', payload };
    }
};

export const saveLeadRemarks = (postData, client) => {
    return async (dispatch) => {
        try {
            const response = await executeGraphQLMutation(SAVE_REMARKS, postData, client);
            if (response && response.data.save_remark && response.data.save_remark.lead_id) {
                return {
                    status: true,
                };
            } else
                return {
                    status: false,
                };
        } catch (err) {
            return {
                status: false,
                response: err,
            };
        }
    };
};

export const getMasterData = (client, vehicle_type = 0) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(GET_MASTER_DATA(vehicle_type), client)
                .then((response) => {
                    if (response && response.data && response.data.masterdata) {
                        dispatch(success(response.data.masterdata));
                    } else dispatch(success({}));
                })
                .catch((error) => {
                    dispatch(success({}));
                });
        } catch (err) {
            dispatch(success({}));
        }
    };
    function success(payload) {
        return { type: 'GET_MASTER_DATA', payload };
    }
};

export const getStatusList = (client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(GET_STATUS_LIST(), client)
                .then((response) => {
                    if (response?.data?.get_status_list) {
                        dispatch(success(response?.data?.get_status_list));
                    } else dispatch(success({}));
                })
                .catch((error) => {
                    dispatch(success({}));
                });
        } catch (err) {
            dispatch(success({}));
        }
    };
    function success(payload) {
        return { type: 'GET_STATUS_LIST', payload };
    }
};

export const getAgentList = (client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(GET_AGENT_LIST(), client)
                .then((response) => {
                    if (response?.data?.agent_list) {
                        dispatch(success(response?.data?.agent_list));
                    } else dispatch(success([]));
                })
                .catch((error) => {
                    dispatch(success([]));
                });
        } catch (err) {
            dispatch(success([]));
        }
    };
    function success(payload) {
        return { type: 'GET_AGENT_LIST', payload };
    }
};

export const opsExecutiveList = (client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(OPS_EXECUTIVE_LIST(), client)
                .then((response) => {
                    if (response?.data?.ops_executive_list) {
                        dispatch(success(response?.data?.ops_executive_list));
                    } else dispatch(success([]));
                })
                .catch((error) => {
                    dispatch(success([]));
                });
        } catch (err) {
            dispatch(success([]));
        }
    };
    function success(payload) {
        return { type: 'OPS_EXECUTIVE_LIST', payload };
    }
};

export const getUserAccess = (postData, client) => {
    return async (dispatch) => {
        try {
            let data;
            await executeGraphQLQuery(GET_ACCESS_FEATURES(postData), client)
                .then((response) => {
                    // console.log(response)
                    if (response && response.data && response.data.get_user_access_features) {
                        data = response.data.get_user_access_features;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            if (data && data.length) {
                let formattedUserAccess = [];
                // if feature exist in multiple role for same user (formatting the features array)
                data &&
                    data.forEach((item) => {
                        let exist_index = formattedUserAccess.findIndex(
                            (v) => v.product_type === item.product_type && v.action === item.action,
                        );
                        if (exist_index > -1) {
                            // To save '1' if both 1 and 0 are present
                            let newAccessType = Math.max(
                                Number(item.access_type),
                                Number(formattedUserAccess[exist_index]['access_type']),
                            );
                            formattedUserAccess[exist_index]['access_type'] = newAccessType
                                ? newAccessType.toString()
                                : '0';
                        } else {
                            formattedUserAccess.push(item);
                        }
                    });
                // console.log(formattedUserAccess)
                localStorage.setItem('userAccess', JSON.stringify(formattedUserAccess));
                return {
                    status: true,
                    response: 'success',
                    data: data,
                };
            } else
                return {
                    status: false,
                    response: 'success',
                };
        } catch (err) {
            return {
                status: false,
                response: err,
            };
        }
    };
};

export const getVehicleUsage = (client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(GET_VEHICLE_USAGE(), client)
                .then((response) => {
                    if (response?.data?.get_vehicle_usage) {
                        dispatch(success(response.data.get_vehicle_usage));
                    } else dispatch(success({}));
                })
                .catch((error) => {
                    dispatch(success({}));
                });
        } catch (err) {
            dispatch(success({}));
        }
    };
    function success(payload) {
        return { type: 'GET_VEHICLE_USAGE', payload };
    }
};

export const getMasterDataMMV = (client, vehicle_type = 0) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(GET_MASTER_DATA_MMV(vehicle_type), client)
                .then((response) => {
                    if (response && response.data && response.data.masterdata) {
                        dispatch(success(response.data.masterdata));
                    } else dispatch(success({}));
                })
                .catch((error) => {
                    dispatch(success({}));
                });
        } catch (err) {
            dispatch(success({}));
        }
    };
    function success(payload) {
        // console.log('=========GET_MASTER_DATA', payload)
        return { type: 'GET_MASTER_DATA_MMV', payload };
    }
};

export const getDealerList = (client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(GET_DEALER_LIST(), client)
                .then(async (response) => {
                    if (response?.data?.get_dealer_list?.dealer_list) {
                        dispatch(success(response?.data?.get_dealer_list?.dealer_list || []));
                    }
                    else {
                        dispatch(success([]));
                    }
                })
                .catch((error) => {
                    dispatch(success([]));
                });
        } catch (err) {
            dispatch(success([]));
        }
    };
    function success(payload) {
        return { type: 'GET_DEALER_LIST', payload };
    }
};

export const getBankList = (client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(GET_BANKS, client)
                .then(async (response) => {
                    if (response?.data?.get_banks) {
                        dispatch(success(response?.data?.get_banks || []));
                    }
                    else {
                        dispatch(success([]));
                    }
                })
                .catch((error) => {
                    dispatch(success([]));
                });
        } catch (err) {
            dispatch(success([]));
        }
    };
    function success(payload) {
        return { type: 'GET_BANKS', payload };
    }
};

export const getFinancierList = (client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(FINANCIER_QUERY, client)
                .then(async (response) => {
                    if (response?.data?.financier_list) {
                        dispatch(success(response?.data?.financier_list || []));
                    }
                    else {
                        dispatch(success([]));
                    }
                })
                .catch((error) => {
                    dispatch(success([]));
                });
        } catch (err) {
            dispatch(success([]));
        }
    };
    function success(payload) {
        return { type: 'GET_FINANCIER', payload };
    }
};

export const getRolesWithUsers = (client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(GET_ROLES_WITH_USERS, client)
                .then(async (response) => {
                    if (response?.data?.get_roles_with_users) {
                        const soUsers = response?.data?.get_roles_with_users?.find(roles => roles?.role_id === 6)?.users?.map((user) => {
                            return { value: user.id, label: user.name };
                        });
                        dispatch(success(soUsers || []));
                    }
                    else {
                        dispatch(success([]));
                    }
                })
                .catch((error) => {
                    dispatch(success([]));
                });
        } catch (err) {
            dispatch(success([]));
        }
    };
    function success(payload) {
        return { type: 'GET_SALES_OFFICER', payload };
    }
};

export const tlRoleList = (client) => {
    return async (dispatch) => {
        try {
            executeGraphQLQuery(TL_USER_LIST(), client)
                .then((response) => {
                    if (response?.data?.tl_user_list) {
                        dispatch(success(response?.data?.tl_user_list));
                    } else dispatch(success([]));
                })
                .catch((error) => {
                    dispatch(success([]));
                });
        } catch (err) {
            dispatch(success([]));
        }
    };
    function success(payload) {
        return { type: 'TL_USER_LIST', payload };
    }
};
