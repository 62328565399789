import { useEffect, useState } from "react";
import Loader from "../../elements/Loader";
import { useApolloClient } from "@apollo/client";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { CREATE_EXCEPTION_MUTATION, EXCEPTION_CASE_REASON } from "../../../services/leads.gql";
import MultiSelect from "../../elements/MultiSelect";
import { getLoanDetail } from "../../../store/action/allAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddExceptionalCase = ({ lead, user_id, role_id, lead_id, dealer_id, hideModalExceptionalcase }) => {
  const [loading, setLoading] = useState(true);
  const [exceptions, setExceptions] = useState([]);
  const [exceptionReason, setExceptionReason] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedCheckbox, setSelectedCheckBox] = useState([]);
  const [remarks, setRemarks] = useState('');
  const client = useApolloClient();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchReasons();
  }, [lead]);

  const navigate = useNavigate()
  const fetchReasons = async () => {
    try {
      const response = await executeGraphQLQuery(EXCEPTION_CASE_REASON(), client, { lead_id: lead?.leadDetail?.id });
      setLoading(false);
      const reasons = response.data?.get_exception_reasons || [];
      setExceptions(reasons);

      const reasonsObject = reasons.reduce((acc, reason) => {
        const transformedDocs = reason.docs.map(({ doc_id, doc_name }) => ({
          value: doc_id,
          label: doc_name,
        }));
        acc[reason.reason_name] = transformedDocs;
        return acc;
      }, {});
      setExceptionReason(reasonsObject);

      // Initialize selected values for all reasons
      const initialSelectedValues = reasons.reduce((acc, reason) => {
        acc[reason.reason_name] = [];
        return acc;
      }, {});
      setSelectedValues(initialSelectedValues);
    } catch (error) {
      console.error("Error fetching reasons:", error);
    }
  };

  const handleMultiSelectChange = (reasonName, selectedOptions) => {
    setSelectedValues((prev) => {
      const currentSelected = prev[reasonName] || [];
      const newSelected = selectedOptions.filter(
        (option) => !currentSelected.some((item) => item.value === option.value)
      );
      return {
        ...prev,
        [reasonName]: [...currentSelected, ...newSelected],
      };
    });
  };

  const handleCheckbox = (e, reason_name) => {
    if (e.target.checked) {
      setSelectedCheckBox((prev) => [...prev, reason_name]);
    } else {
      setSelectedCheckBox((prev) => prev.filter((data) => data !== reason_name));
      setSelectedValues({
        ...selectedValues,
        [reason_name]: [],
      });
    }
  };

  const handleRemove = (reason_name, selectedReason) => {
    const { value } = selectedReason;
    setSelectedValues((prev) => {
      const updatedValues = { ...prev };
      updatedValues[reason_name] = updatedValues[reason_name].filter(
        (item) => item.value !== value
      );
      return updatedValues;
    });
  };

  const isSubmitEnabled = () => {
    const otherReasonSelected = selectedCheckbox.includes("Other Mistakes");
    if (otherReasonSelected && !remarks.trim()) {
      return false;
    }
    return (
      selectedCheckbox.length > 0 &&
      selectedCheckbox.every((checkbox) =>
        checkbox === "Other Mistakes" ? true : selectedValues[checkbox]?.length > 0
      )
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    let missingDocs = [];

    exceptions.forEach((exception) => {
      if (selectedValues[exception.reason_name].length !== 0) {
        missingDocs.push({
          reason_id: exception.id,
          reasons: JSON.stringify(selectedValues[exception.reason_name]),
        });
      }
    });

    let lead_exception = lead?.leadDetail?.id || 0;

    let createExceptionData = {
      lead_id: lead_exception,
      exception_reason: missingDocs,
      remarks: remarks,
    };

    if (lead?.leadDetail?.dealer_id === 0) {
      createExceptionData.email = lead?.leadDetail?.customer.email
      createExceptionData.customerName = lead?.leadDetail?.customer.name
    }

    let variables = {
      api_called_by: "web",
      user_id,
      role_id,
      dealer_id: lead?.leadDetail?.dealer_id,
      createExceptionData: createExceptionData,
    };

    executeGraphQLMutation(CREATE_EXCEPTION_MUTATION(), variables, client)
      .then(async (res) => {
        toast.success(res?.data?.create_exception?.message);
        dispatch(getLoanDetail(lead_exception, client));
        hideModalExceptionalcase();
        navigateToNext();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  const navigateToNext = () => {
    navigate(`/lead-detail/customer-details/exceptional/${lead_id}`);
  }

  return (
    <>
      {loading && <Loader />}
      <div className="exceptional-case-list">
        <ul>
          {exceptions.map((exception, idx) => (
            <li key={idx}>
              <div className="custom-control custom-checkbox">
                <input
                  id={`checkbox_${idx}`}
                  type="checkbox"
                  className="custom-control-input"
                  onChange={(e) => handleCheckbox(e, exception.reason_name)}
                />
                <label
                  htmlFor={`checkbox_${idx}`}
                  className="custom-control-label"
                >
                  {exception.reason_name}
                </label>
              </div>

              {/* Hide MultiSelect for "Other Reason" */}
              {exception.reason_name.toLowerCase() !== "other mistakes" &&
                selectedCheckbox.includes(exception.reason_name) && (
                  <fieldset className="single-select p-md-t">
                    <div className="material">
                      <MultiSelect
                        options={exceptionReason[exception.reason_name] || []}
                        values={selectedValues[exception.reason_name]?.map(data => data.value) || []}
                        onChange={(name, selectedOptions) =>
                          handleMultiSelectChange(exception.reason_name, selectedOptions)
                        }
                        placeholder={"Select Service Type"}
                        name={exception.reason_name}
                        optionLabel={"label"}
                        optionValue={"value"}
                        className="react-select"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </fieldset>
                )}

              <div className="selected-chips">
                <ul>
                  {selectedValues[exception.reason_name]?.map((selected, idx) => (
                    <li key={idx}>
                      {selected.label}
                      <span>
                        <i
                          className="ic-clearclose"
                          onClick={() =>
                            handleRemove(exception.reason_name, selected)
                          }
                        ></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
        <fieldset className="form-field p-md-b">
          <div className="material">
            <textarea
              placeholder=" "
              className="form-input"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              required={selectedCheckbox.includes("Other Reason")}
            ></textarea>
            <label data-label="Add Remarks" className="form-label"></label>
          </div>
        </fieldset>
      </div>
      <button
        className="btn-primary btn-auto-width"
        onClick={handleSubmit}
        disabled={!isSubmitEnabled()}
      >
        Send to Dealer for Approval
      </button>
    </>
  );
};

export default AddExceptionalCase;



