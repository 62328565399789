import _ from 'lodash';

export const SETTING = {
    CRYPTO_KEY: 'U*oDwrIU!B#QX37%#24',
    RESEND_OTP_TIME_IN_SECOND: 30,
};
export const API_URL = process.env.REACT_APP_GETWAY_API;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const TL_ROLE_ID = process.env.REACT_APP_TL_ROLE_ID || 43;
export const TL_ROLE = process.env.REACT_APP_TL_ROLE || "TL Role";
export const getFileExtensionFromURL = (url) => {
    try {
        return url && url.split(/#|\?/)[0].split('.').pop().trim();
    } catch (e) {
        return '';
    }
};

export const ADMIN_ROLE_ID = 1;

export const IS_USER_ACCESS_PAGE = (controller, action) => {
    let isUserHasRights = false,
        isEditAccess = false;
    // let loginData = localStorage.getItem("loginUserInfo");
    let loginData = JSON.parse(localStorage.getItem('user_information'));
    let isSuperAdmin = loginData && loginData?.role_id?.includes(1);

    if (isSuperAdmin) {
        isUserHasRights = true;
        isEditAccess = true;
    } else {
        const userAccessFeatures = JSON.parse(localStorage.getItem('userAccess'));
        if (userAccessFeatures && userAccessFeatures.length) {
            let isKeyExist = _.findKey(userAccessFeatures, function (v) {
                if (controller && v.controller && action && v.action) {
                    return (
                        v.controller.toLowerCase() === controller.toLowerCase() &&
                        v.action.toLowerCase() === action.toLowerCase()
                    );
                }
            });
            isUserHasRights = isKeyExist ? true : false;
            isEditAccess = isKeyExist
                ? Number(userAccessFeatures[isKeyExist].access_type) === 1
                    ? true
                    : false
                : false;
        }
    }

    return {
        is_super_admin: isSuperAdmin,
        is_access_page: isUserHasRights,
        is_edit_access: isEditAccess,
    };
};

export const CHECK_ASSIGN_USER = (assign_to) => {
    let loginData = JSON.parse(localStorage.getItem('user_information'));
    let isSuperAdmin = loginData && loginData.role_id.includes(1);
    if (isSuperAdmin) return false;
    return !(assign_to === loginData?.id);
};

export const STATUS_CODES = {
    APPLICATION: 1,
    DOCUMENTS_UPLOADED: 2,
    PAYMENT_PROOF_UPLOADED: 3,
    SENT_TO_AGENT: 4,
    IN_PROGRESS: 5,
    ORCR_GENERATED: 6,
    LOST: 7,
};

export const SUB_STATUS_CODES = {
    DOCUMENT_NOT_COMPLETED: 1,
    NON_SERVICEABLE_AREA: 2,
    REJECTED_BY_AGENT: 3,
    OTHER: 4,
};

export const PAYMENT_PROOF_DOCS = {
    MAIN_DEALER: 108,
    MAIN_DEALER_PARENT: 107,
    SPLIT_DEALER_1: 123,
    SPLIT_DEALER_1_PARENT: 122,
    SPLIT_DEALER_2: 125,
    SPLIT_DEALER_2_PARENT: 124,
};

export const NAME_WITH_LATIN_CHARACTER_REGEX = /^[^0-9]*$/;

export const calculatePercentageValue = (value = 0, percentage = 0) => {
    return (value * percentage) / 100;
};

export const COUNTRY_CODE = '+63';
export const COUNTRY_CODE_REGEX = /^\+63\s*/;

export const SEARCH_TYPE = [
    { value: 'loan_id', label: 'Loan Id' },
    { value: 'customer_name', label: 'Customer name' },
    { value: 'customer_number', label: 'Customer number' },
    { value: 'plate_number', label: 'Plate number' },
];

export const DATE_TYPE = [
    { value: '1', label: `Application created date` },
    { value: '2', label: `Documents uploaded date` },
    { value: '3', label: `Payment proof uploaded date` },
    { value: '4', label: `Sent to agent date` },
    { value: '5', label: `In progress lto office date` },
    { value: '6', label: `Orcr transfer completed date` },
    { value: '7', label: `Lost date` },
];

export const ORDERBY_LEAD_FIELDS = [
    { value: 'id__ASC', label: 'Appl. ID ASC' },
    { value: 'id__DESC', label: 'Appl. ID DESC' },
    { value: 'created_date__ASC', label: 'Created Date ASC' },
    { value: 'created_date__DESC', label: 'Created Date DESC' },
    { value: 'updated_date__ASC', label: 'Modified Date ASC' },
    { value: 'updated_date__DESC', label: 'Modified Date DESC' },
];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_YMD_HMS = 'YYYY-MM-DD HH:mm:ss';
export const ACCEPTED_BY_AGENT_YMD = 'DD/MM/YYYY hh:mm A';

export const UPDATE_LEAD_ORCR_ACTION = {
    NEW_ORCR: 'NEW_ORCR',
    ORCR_SENT_TO_DEALER: 'ORCR_SENT_TO_DEALER',
};

export const DOC_IDS = {
    NEW_ORCR: 18,
};

export const LEAD_STATUS = {
    APPLICATION_CREATED: 1,
    DOCUMENTS_UPLOADED: 2,
    PAYMENT_PROOF_UPLOADED: 3,
    SENT_TO_AGENT: 4,
    IN_PROGRESS: 5,
    NEW_ORCR_GENERATED: 6,
    LOST: 7,
    NEW_ORCR_SENT_TO_DEALER: 8,
    ACCEPTED_BY_AGENT: 9,
    REJECTED_BY_AGENT: 10,
    EXCEPTIONAL_CASE_PENDING_AT_DEALER: 11,
    EXCEPTIONAL_CASE_PENDING_AT_TL: 12,
    EXCEPTION_CASE_APPROVED_BY_TL: 13,
    EXCEPTION_CASE_REJECTED_BY_TL: 14,
    AGENT_SELECTION_PENDING_AT_TL: 15,
    AGENT_SELECTION_APPROVED_BY_TL: 16,
    AGENT_SELECTION_REJECTED_BY_TL: 17
};

export const LEAD_SUB_STATUS = {
    NEW_ORCR: 5,
    ORCR_SENT_TO_DEALER: 6,
};

export const AGENT_ACTION = {
    accepted: 'accepted',
    rejected: 'rejected',
};

export const getDocExtension = (doc_path) => {
    return doc_path?.split('?')[0].split('.').pop() || '';
};

export const DEALER_SERVICE_TYPE_IDS = [1, 2, 4, 5];
export const INDIVIDUAL_SERVICE_TYPE_IDS = [3];

export const FILTER_KEY_CHIPS_VALUE = {
    service_type_id: "Service Type",
    transfer_type_id: "Transfer Type",
    status_id: "Status",
    agent_id: "Agent",
    dealer_id: "Dealer",
    search_text: "Search",
    reportee_role_id: "Reportee Role",
    reportee_user_ids: "Reportee User",
    source_type_id: "Source Type",
    search_type: "Search Type",
    date_type: "Date Type",
    from_date: "From Date",
    to_date: "To Date",
    case_type: "Case Type",
    payment_status: "Payment Received Status",
    payment_paid_status: "Payment Paid Status",
    lead_source_tagging: "Case Type",
    stages: "Stages",
    is_delayed: "Delayed",
    oe_user: "Ops Executive",
    lead_doc_status: "Document status",
    tl_user: "TL User",
};

export const EXTRACT_NUMBER_REGEX = /\D/g;

export const LEADS_ACTION_TYPE = {
    all_cases: `all_cases`,
};


export const getKeysDataIfExist = (object = {}, array = []) => {
    return (
        array.reduce((result, key) => {
            if (Array.isArray(key)) {
                if (object[key[0]]) result[key[1]] = (key?.[2] && +object[key[0]]) || object[key[0]];
            } else {
                if (object[key]) result[key] = object[key];
            }
            return result;
        }, {}) || {}
    );
};

export const ACTION_TYPE_TAB_MAPPING = {
    "in_process_cases": "tb_in_progress_",
    "completed_cases": "tb_completed_",
    "lost_cases": "tb_lost_",
};

export const MAX_VERIFICATION_LIMIT = 10;

export const TITLE_BOX_DIF_STATUS = {
    'RECIEVED': 1,
    'VERIFIED': 2,
    'NOT_VERIFIED': 3
};

export const PAYMENT_TYPE = {
    paid: "paid",
    received: "received"
};

export const LEAD_PAYMENT_STATUS = [
    {
        label: "Pending",
        value: "Pending"
    },
    {
        label: "Received",
        value: "Received"
    }];

export const LEAD_PAYMENT_PAID_STATUS = [
    {
        label: "Pending",
        value: "Pending"
    },
    {
        label: "Paid",
        value: "Paid"
    }];

export const CASE_TYPE_OPTIONS = [
    {
        label: "Fresh",
        value: "Fresh"
    },
    {
        label: "Existing",
        value: "Existing"
    }];

export const CAR_UNDER_FINANCING_OPTIONS = [
    {
        label: "Yes",
        value: "Yes"
    },
    {
        label: "No",
        value: "No"
    }];

export const FINANCING_FROM_OPTIONS = [
    {
        label: "Carmudi",
        value: "Carmudi"
    },
    {
        label: "External",
        value: "External"
    }];

export const FIELDS_TO_DISABLE = {
    with_ucf:
        [
            'name',
            'mobile',
            'plate_number',
            'so_id',
            'sm_id',
            'th_id',
            'nh_id',
            'dealer_id',
            'email',
            'house_no',
            'street',
            'brangey_id',
            'city_id',
            'region_id',
            'postal_code',
            'plate_number',
            'make_id',
            'model_id',
            'variant_id',
            'make_name',
            'model_name',
            'variant_name',
            'make_year',
            'car_color',
            'vehicle_usage',
            'vehicle_type_id',
            'vehicle_category_id',
            'other_make_name',
            'other_model_name',
            'other_variant_name',
            'other_vehicle_type'
        ],
    add_app_with_ucf: [
        'name',
        'mobile',
        'plate_number',
        'standard_ttp']
};

export const fieldsNeedToDisable = (field_name, value, lead_type) => {
    return (value || (['make_id', 'model_id', 'variant_id'].includes(field_name) && value === 0)) && FIELDS_TO_DISABLE[lead_type].includes(field_name);
};

export const addApplicationFieldsNeedToDisable = (field_name) => {
    return FIELDS_TO_DISABLE?.add_app_with_ucf.includes(field_name);
};

export const REVIEW_PAGES = {
    STATUS_TRACKING: 'status_tracking',
    ORCR_SEND_TO_DEALER: 'orcr_send_to_dealer'
};

export const LEAD_SOURCE_TAGGING = [
    { value: 'uc_attached_adp', label: 'UC Attached & ADP' },
    { value: 'uc_attached', label: 'UC Attached' },
    { value: 'uc_stand_alone', label: 'UC Standalone' },
    { value: 'new_initiatives', label: 'New Initiatives' },
    { value: 'individual', label: 'Individual' },
];

export const UPDATE_STAGE_STAUS_OPTIONS = [
    { value: 1, label: "HPG Clearance" },
    { value: 2, label: "Registry of Deed" },
    { value: 3, label: "LTO Confirmation Stage" },
    { value: 4, label: "LTO Processing Stage" },
    { value: 5, label: "LTO Release Stage" },
];

export const HPG_CLEARANCE_ID = 3;

export const LEAD_DELAYED_OPTIONS = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" }
];

export const GOOGLE_AUTH_URL = process.env.REACT_APP_GOOGLE_AUTH_URL || "https://sso.carmudi.com.ph/google?redirect=";


export const PAYMENT_METHOD_OPTIONS = [
    { value: 1, label: "Online Banking" },
    { value: 2, label: "G Cash" }
];

export const SERVICE_TYPE_VALIDATION_IDS = {
    OLDER: [4, 5, 3],
    GREATER: [1, 2, 3]
};

export const SERVICE_TYPE_ID_TOGGLE = {
    NEUTRAL: [3],
    1: 4,
    2: 5,
    4: 1,
    5: 2
};

export const DOC_STATUS_OPTIONS = [
    {
        id: "0", label: "Pending"
    },
    {
        id: "1", label: "Submitted"
    },
    {
        id: "2", label: "Approved"
    },
];

export const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const FETCH_TYPE = {
    FEE: "FEE",
    TAT: "TAT"
};

export const generateKeyValuePair = (rows = [], key, value) => {
    return rows?.reduce((result, element) => { result[element?.[key]] = value ? (element?.[value] || "") : element || {}; return result; }, {});
};

export const TL_APPROVAL_ACTION = {
    'pending': 'pending',
    'approved': 'approved',
    'rejected': 'rejected'
};
export const AGENT_LTO_UPDATE_TYPE = {
    LTO: "LTO",
    AGENT: "AGENT",
};

export const AgentEntryType = {
    New: 'New',
    Old: 'Old',
};

export const MAX_AGENT_LIMIT = 3;
export const replaceErrorMessage = (str = "") => str.replace(/ApolloError:/g, "") || "";
export const STANDALONE_ORCR_STATUS = {
    'RECIEVED': 1,
    'VERIFIED': 2,
    'NOT_VERIFIED': 3
};

export const DOC_STATUS_MAP = {
    APPROVED: "2",
    SUBMITTED: "1",
    PENDING: "0"
};

export const VERIFICATION_DOC_IDS = {
    'standaloneorcr': 4,
    'iforcr': 6,
    'ucforcr': 6
};