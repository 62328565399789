import { gql } from '@apollo/client';
export const AGENTS_QUERY = () => {
    return gql`
        query GetAgents(
            $getAgentsInput: GetAgentsInput!
            $paginationInput: PaginationInput!
            $api_called_by: API_CALL_BY!
        ) {
            get_agents(filters: $getAgentsInput, paginationInput: $paginationInput, api_called_by: $api_called_by) {
                rows {
                    id
                    agent_name
                    agent_mobile
                    agent_email
                    agent_code
                    payment_method
                    account_number
                    bank_name_id
                    bank_name {
                        id
                        name
                    }
                    branch_name
                    beneficiary_name
                    status
                    created_date
                    lto_office_agent {
                        id
                        agent_id
                        lt_office_id
                        region_id
                        lto_office_region {
                            id
                            region_name
                        }
                        lto_office {
                            id
                            lt_office_name
                            status
                            created_date
                        }
                        lto_agent_service {
                            id
                            lto_office_agent_id
                            service_id
                            agent_fee
                            agent_tat
                            service_details {
                                id
                                service_type
                                standard_price
                            }
                        }
                    }
                    agent_docs {
                        id
                        agent_id
                        doc_id
                        parent_doc_id
                        doc_path
                        signed_doc_path
                        doc_name
                    }
                }
                pagination {
                    total_page
                    total_records
                    prev_page
                    next_page
                    page_no
                }
            }
        }
    `;
};

export const LEADS_REMARK_QUERY = (lead_id) => gql`
query{
  get_remarks(lead_id:${lead_id}){
    remark
    created_date
    section
    sub_section
  	user_details{
      added_by_name
    }
  }
}
`;

export const LEADS_HISTORY_QUERY = (lead_id) => gql`
    query {
        get_lead_history(lead_id: ${lead_id}) {
            id
            lead_id
            status_id
            user_id
            created_date
            lead_status {
                id
                name
            }
            user_details {
                id
                name
            }
        }
    }
`;
export const LEAD_DETAIL_QUERY = (lead_id) => gql`
    query {
        lead_details(lead_id: ${lead_id}) {
            user_details {
                id
                name
            }
            dealer_details {
                organization
                gcd_code
            }
            lead_docs {
                id
                lead_id
                doc_id
                parent_doc_id
                doc_path
                doc_source
                description
                doc_status
            }
            dealer_name
            so_name
            is_delayed
            id
            loan_id
            lead_source
            created_by_source_id
            created_by_source
            service_type_id
            service_coverage_id
            service_type {
                service_type
            }
            service_coverage {
                name
            }
            status_id
            doc_status
            dealer_id
            so_id
            sm_id
            th_id
            nh_id
            lead_assigned_to
            lead_assigned_date
            updated_by
            status
            created_date
            lead_detail {
                id
                lead_id
                standard_ttp
                inbound_lt_office_id
                outbound_lt_office_id
                agent_fee
                stensil
                created_date
                updated_date
                status
            }
            lead_vehicle_detail {
                id
                lead_id
                make_id
                model_id
                variant_id
                make_name
                model_name
                variant_name
                vehicle_usage
                vehicle_type_id
                vehicle_category_id
                vehicle_type {
                    id
                    name
                }
                plate_number
                make_year
                chassis_number
                engine_number
                fuel_type
                car_color
                remarks
                area_id
                created_date
                updated_date
                status
            }
            customer {
                id
                lead_id
                name
                email
                mobile
                house_no
                street
                region_id
                city_id
                brangey_id
                postal_code
                status
                created_date
            }
            lead_status {
                id
                name
            }
            lead_docs {
                id
                lead_id
                doc_id
                parent_doc_id
                doc_path
                doc_source
                description
                doc_status
            }
        }
    }
`;

export const DOCUMENT_LIST = (financier_id, template_for) => gql`query{
  
  financierListbyID(financier_id:"${financier_id}",template_for:"${template_for}"){
    id
    financier_id
    template_type
    financier_group_name
    financier_group_email
    template_body
    financier_group_mobile
  }
}
`;

export const GET_SUB_STATUS = (status_ids) => gql`
  query{
    sub_status_list(status_ids:"${status_ids}"){
      substatuslang{
        sub_status_name,
        sub_status_id
      }
    }
  }`;

export const MARK_AS_LOST = gql`
    mutation markAsLost(
        $action_type: String!
        $lead_id: Float!
        $sub_status_id: Float!
        $comment: String!
        $user_id: Float!
    ) {
        markAsLost(
            action_type: $action_type
            lead_id: $lead_id
            status_id: 7
            sub_status_id: $sub_status_id
            remark: $comment
            user_id: $user_id
        ) {
            message
        }
    }
`;

export const GET_DOCUMENTS_LIST = () => gql`
    query {
        getDocumentList(api_called_by: web) {
            id
            parent_id
            tab_name
            doc_section
            doc_type
            is_co_borrower
            customer_type
            is_required
            is_required_app
            show_in_app
            min_upload
            max_upload
            doclang {
                name
                doc_id
            }
            child {
                id
                parent_id
                tab_name
                doc_section
                doc_type
                is_co_borrower
                customer_type
                is_required
                is_required_app
                show_in_app
                min_upload
                max_upload
                doclang {
                    name
                    doc_id
                }
            }
        }
    }
`;

export const SEND_DOC_TO_FINANCEIR = gql`
    mutation sendtemplatenotification($sendTemplateNotificationinput: SendTemplateNotificationInput!) {
        sendtemplatenotification(sendTemplateNotificationinput: $sendTemplateNotificationinput, api_called_by: "web") {
            message
        }
    }
`;

export const SAVE_COMPUTATION_DETAILS = gql`
    mutation saveLeadComputationDetails($leadComputationDetailsInput: LeadComputationDetailInput!) {
        saveLeadComputationDetails(leadComputationDetailsInput: $leadComputationDetailsInput) {
            id
        }
    }
`;

export const STATUS_QUERY = gql`
    query {
        status_list {
            statuslang {
                status_name
                status_id
            }
        }
        financier_list {
            financierlang {
                financier_name
                financier_id
            }
        }

        sub_status_list(status_ids: "") {
            status_id
            substatuslang {
                sub_status_name
                sub_status_id
            }
        }
    }
`;

export const UPDATE_LEAD_STATUS = gql`
    mutation updateleadstatus($updateleadstatus: UpdateLeadStatus!) {
        updateleadstatus(updateleadstatus: $updateleadstatus, api_called_by: "web") {
            message
        }
    }
`;

export const SAVE_CSV_DOWNLOAD_REQ = gql`
    mutation save_csv_download_req($applied_filters: String!, $user_id: Float!, $user_email: String!) {
        save_csv_download_req(applied_filters: $applied_filters, user_id: $user_id, user_email: $user_email) {
            message
        }
    }
`;
export const COMPUTATION_MASTER_DATA = (financier_id) => {
    return gql`query{
    computationMasterDetails(financier_id: ${financier_id}) {
      financier_id
      name
      from_year
      to_year
      interest_rate
      chattel_fee
      tenure_in_month
      processing_fee
      amount_financed_min
      amount_financed_max
      vehicle_category_id
      dealer_incentive_gross
      dealer_tax
      dealer_incentive_net
      carmudi_top_up_gross
      carmudi_tax
      carmudi_top_up_net
      total_gross_di
      di_tax
      total_net_di
      vat
      di_payment_from_financier_gross
      revenue
      di_payment_from_financier_tax
      di_payment_from_financier_net
    }
  }`;
};

export const SAVE_COMPUTATION_CONFIG = gql`
    mutation saveComputationConfig($fileComputationData: [CalculatorConfigInput!]!) {
        saveComputationConfig(updates: $fileComputationData) {
            id
        }
    }
`;

export const LTO_AGENT_SERVICE_CHARGE = gql`
    query Lto_agent_service_charge($lto_agent_service: GetLtoAgentServiceChargeDto!) {
        lto_agent_service_charge(lto_agent_service: $lto_agent_service) {
            id
            lto_office_agent_id
            service_id
            agent_fee
            status
            created_date
            updated_date
            lto_office_agent {
                id
                agent_id
                lt_office_id
                status
                created_date
                updated_date
            }
            service_details {
                id
                service_type
                standard_price
            }
        }
    }
`;

export const SEND_TO_AGENT = gql`
    mutation Send_to_agent($user_id: Float!, $role_id: Float!, $send_to_agent: SendToAgentDto!) {
        send_to_agent(user_id: $user_id, role_id: $role_id, send_to_agent: $send_to_agent) {
            message
        }
    }
`;

export const ADD_AGENT = gql`
    mutation Add_agent($api_called_by: API_CALL_BY!, $add_agent_input: AddAgentInput!, $user_id: Float!, $role_id: Float!) {
        add_agent(
            api_called_by: $api_called_by, add_agent_input: $add_agent_input, user_id: $user_id,role_id: $role_id        
        ) {
            message
            agent_id
        }
    }
`;

export const SAVE_AGENT_SERVICE = gql`
    mutation Save_agent_service($api_called_by: API_CALL_BY!, $save_agent_service_input: SaveAgentServiceInput!, $user_id: Float!, $role_id: Float!) {
        save_agent_service(save_agent_service_input: $save_agent_service_input,api_called_by: $api_called_by, user_id: $user_id,role_id: $role_id) {
            message
        }
    }
`;
